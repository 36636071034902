import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { isLoaded, isLoading, isError, isAccessError } from '../../state/app/selectors'
import { loadInfo, clearPath } from '../../state/app/actions'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import Toolbar from '@material-ui/core/Toolbar'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  load: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexGrow: 1,
    minHeight: 200,
    alignItems: 'center',
  },
}))

export default function Load({ path, params, children }) {
  const dispatch = useDispatch()
  const isDataLoaded = useSelector(isLoaded(path))
  const isDataLoading = useSelector(isLoading(path))
  const isDataError = useSelector(isError(path))
  const isAccessDenied = useSelector(isAccessError(path))
  const classes = useStyles()
  const [appliedParams, setAppliedParams] = useState({ not_initialised: true })

  useEffect(() => {
    if (!_.isEqual(appliedParams, params)) {
      setAppliedParams(params)
      dispatch(loadInfo(path, params))
    }
  }, [dispatch, path, params, appliedParams, setAppliedParams])

  useEffect(() => {
    return () => {
      //clear data on unmount
      dispatch(clearPath(path))
    }
  }, [path, dispatch])

  if (isDataLoading) {
    return (
      <div className={classes.root}>
        <Toolbar />
        <div className={classes.load}>
          <CircularProgress />
        </div>
      </div>
    )
  }

  if (isDataLoaded && !isDataError && !isAccessDenied) {
    return <>{children}</>
  }

  if (isAccessDenied) {
    return (
      <div className={classes.root}>
        <Toolbar />
        <Alert severity={'error'}>
          Authentication error: user has no access or session has expired. Please refresh the page
          or log in as a different user.
        </Alert>
      </div>
    )
  }

  if (isDataError) {
    return (
      <div className={classes.root}>
        <Toolbar />
        <Alert severity={'error'}>
          Server error: data could not be loaded. Please try again later.
        </Alert>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Toolbar />
      <div className={classes.load}>
        <CircularProgress />
      </div>
    </div>
  )
}

Load.defaultProps = {
  params: {},
}
