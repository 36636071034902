import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Panel from '../../components/Panel/Panel'
import Load from '../../components/Actions/Load'
import TimePicker, { useTimeRange, DEFAULT_TIMEZONE } from '../../components/Actions/TimePicker'
import { Data } from '../../state/app/types'
import { useSelector } from 'react-redux'
import { getSelectedUser, getOldExtensionLogs } from '../../state/user-management/selectors'
import { getQuery } from '../../state/app/selectors'
import { getDomain } from '../../state/domain-management/selectors'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Modal from '@material-ui/core/Modal'
import ReactJson from 'react-json-view'
import _ from 'lodash'

import { Button, Paper } from '@material-ui/core'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  chipsContainer: {
    padding: theme.spacing(8),
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(8),
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 600,
    maxHeight: 600,
    overflow: 'scroll',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    width: '100%',
  },
  configuration: {
    display: 'flex',
  },
  slider: {
    maxWidth: 200,
  },
  table: {
    minWidth: 650,
  },
}))

const getInfo = (row: any) => {
  return JSON.parse(row[6])
}

export default function OldLogs() {
  const classes = useStyles()
  const user = useSelector(getSelectedUser) as any
  const domain = useSelector(getDomain)
  const oldLogs = useSelector(getOldExtensionLogs)
  const query = useSelector(getQuery)
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [infoModalContent, setInfoModalContent] = useState({})
  const [timezone, setTimezone] = useState(DEFAULT_TIMEZONE)
  const [timeRangeFrom, timeRangeTo] = useTimeRange()

  return (
    <div className={classes.root}>
      <h2>Old User logs</h2>
      <Modal className={classes.modal} open={infoModalOpen} onClose={() => setInfoModalOpen(false)}>
        <div className={classes.paper}>
          <ReactJson src={infoModalContent} />
        </div>
      </Modal>
      <Panel
        header={`User Info: ${
          _.get(user, 'roles').indexOf('student') < 0 ? '' : 'STUDENT  '
        } ${_.get(user, 'primary_email')}`}
      >
        <ReactJson src={user} collapsed={true} style={{ width: '100%' }} />
        <a
          rel="noreferrer"
          href={`https://hugs.teacherdashboard.com/admin/_/#/main/user/urn/${_.get(
            user,
            'primary_email'
          )}/raw`}
          target="_blank"
        >
          Hugs user
        </a>
      </Panel>
      <Panel header={`Domain Info: ${_.get(domain, 'URN')}`}>
        <ReactJson src={domain} collapsed={true} style={{ width: '100%' }} />
        <a
          rel="noreferrer"
          href={`https://hugs.teacherdashboard.com/admin/_/#/main/group/urn/${_.get(
            domain,
            'URN'
          )}/raw`}
          target="_blank"
        >
          Hugs domain
        </a>
      </Panel>
      <TimePicker timezone={timezone} onTimezoneChange={setTimezone} />
      <Load
        path={Data.OldExtensionLogs}
        params={{
          student: _.get(user, 'primary_email'),
          runTime: query.runTime || 0,
          from: timeRangeFrom,
          to: timeRangeTo,
        }}
      >
        <Panel header="Old extension logs">
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableBody>
                {(oldLogs || []).map((row: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="left">
                        {moment(row[0]).tz(timezone).format('HH:mm:ss')}
                      </TableCell>
                      <TableCell align="left">{row[3]}</TableCell>
                      <TableCell align="left">{row[4]}</TableCell>
                      <TableCell align="right">
                        {
                          <Button
                            onClick={() => {
                              setInfoModalContent(getInfo(row))
                              setInfoModalOpen(true)
                            }}
                          >
                            Details
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Panel>
      </Load>
    </div>
  )
}
