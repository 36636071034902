import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { axiosWithInterceptors } from './axios'
import { getToken } from '../auth/handleJWT'

interface NewTaskRequest {
  SourceDomain: string
  SourceCollectionID: string
  TargetDomain: string
  TargetCollectionID: string
  Authorisor: string | null
  AuthoriseTime: string | undefined
  TargetOwner: string | null
  AllowDups: boolean | null
  CopyResourceTypes: CheckboxValueType[]
}

interface CollectionItem {
  id: string
  name: string
}

const requestNewTaskCopy = async (params: NewTaskRequest) => {
  const { access_token } = await getToken()
  await axiosWithInterceptors.post<NewTaskRequest, null>(
    '/library/hap-admin/copy-collection',
    params,
    {
      headers: { Authorization: access_token },
    }
  )
}

const getCollectionsList = async (domain: string) => {
  const { access_token } = await getToken()
  return await axiosWithInterceptors.get<string, CollectionItem[]>(
    `/library/hap-admin/collections/byDomain/${domain}`,
    {
      headers: { Authorization: access_token },
    }
  )
}

export { requestNewTaskCopy, getCollectionsList }
export type { NewTaskRequest, CollectionItem }
