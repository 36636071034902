import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import NavBar from '../../components/Authentication/Layout/NavBar'
import DomainManagementNavigation from './DomainManagementNavigation'
import Load from '../../components/Actions/Load'
import { Route, Redirect, Switch, useParams } from 'react-router-dom'
import DomainSummary from './DomainSummary'
import HighlightsTroubleshooting from './HighlightsTroubleshooting'
import TDSupportUtilities from './TDSupportUtilities/TDSupportUtilities'
import ReportingData from './ReportingData/ReportingData'
import FeatureFlags from './FeatureFlags/FeatureFlags'

import { Data } from '../../state/app/types'
import DigitalBackPack from './DigitalBackPack'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  page: {
    width: '100%',
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

export default function DomainManagementView() {
  const classes = useStyles()
  const { domain } = useParams()
  return (
    <div className={classes.root}>
      <NavBar subheader={domain} />
      <Load path={Data.Domain} params={{ domain }}>
        <DomainManagementNavigation path={`/domain-management/${domain}`} />
        <div className={classes.page}>
          {/* Reserves space */}
          <Toolbar />
          <Switch>
            <Route path={`/domain-management/:domain/summary`} component={DomainSummary} />
            <Route path={`/domain-management/:domain/activity`} component={DomainSummary}></Route>
            <Route path={`/domain-management/:domain/tasks`} component={DomainSummary} />
            <Route path={`/domain-management/:domain/backpack`} component={DigitalBackPack} />
            <Route
              path={`/domain-management/:domain/hl-troubleshooting`}
              component={HighlightsTroubleshooting}
            />
            <Route path={`/domain-management/:domain/td-tools`} component={TDSupportUtilities} />
            <Route path={`/domain-management/:domain/reporting-data`} component={ReportingData} />
            <Route path={`/domain-management/:domain/feature-flags`} component={FeatureFlags} />
            <Redirect
              from={'/domain-management/:domain/'}
              to={`/domain-management/:domain/summary`}
            />
          </Switch>
        </div>
      </Load>
    </div>
  )
}
