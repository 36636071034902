import actionTypes from '../actionTypes'

const INIT_STATE = {
  sideBarOpened: false,
}

const uiReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.UI_OPEN_SIDE_BAR:
      return {
        ...state,
        sideBarOpened: true,
      }
    case actionTypes.UI_CLOSE_SIDE_BAR:
      return {
        ...state,
        sideBarOpened: false,
      }

    default:
      return state
  }
}

export default uiReducer
