import { hot } from 'react-hot-loader/root'
import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import store, { history } from './state/store'
import LandingPage from './views/LandingPage/LandingPage'
import InternalAdminView from './views/InternalAdmin/InternalAdminView'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import DomainManagementView from './views/DomainManagement/DomainManagementView'
import UserManagementView from './views/UserManagement/UserManagementView'
import ClassManagementView from './views/ClassManagement/ClassManagementView'
import UsageReport from './views/UsageReport/index.tsx'
import LoginHandler from './components/Authentication/LoginHandler'
import 'antd/dist/antd.css'

const theme = createTheme({
  spacing: 2,
  typography: {
    subtitle1: {
      fontWeight: 600,
    },
  },
  palette: {
    primary: {
      light: 'blue',
      main: '#252e3d',
      dark: '#516eb7',
    },
    background: {
      default: '#ebebec',
    },
  },
})

function Content() {
  return (
    <ThemeProvider theme={theme}>
      <LoginHandler />
      <CssBaseline />
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/admin" component={LandingPage} />
          <Route path="/domain-management/:domain/:id" component={DomainManagementView} />
          <Route path="/class-management/:class_urn/:id" component={ClassManagementView} />
          <Route path="/user-management/:user/:id" component={UserManagementView} />
          <Route path="/internal-admin/:id" component={InternalAdminView} />
          <Route path="/usage-report" component={UsageReport} />
          <Redirect from="/" to="/admin" />
        </Switch>
      </ConnectedRouter>
    </ThemeProvider>
  )
}

function App() {
  return (
    <Provider store={store}>
      <Content />
    </Provider>
  )
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App
