import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { getStatusDashboard } from '../../state/internal-admin/selectors'
import { Data } from '../../state/app/types'
import Panel from '../../components/Panel/Panel'
import Load from '../../components/Actions/Load'
import { Incident } from '../../apiCalls/types'
import { useMediaQuery, useTheme } from '@material-ui/core'
import classnames from 'classnames'
import Modal from '@material-ui/core/Modal'
import ReactJson from 'react-json-view'
import moment from 'moment'
import ActionDialog from '../../components/Actions/ActionDialog'
import { Actions } from '../../state/app/types'
import {
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

const drawerWidth = 250

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 600,
    maxHeight: 600,
    overflow: 'scroll',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  grid: {
    borderTop: '1px solid gray',
    borderLeft: '1px solid gray',
    borderRight: '1px solid gray',
  },
  orange: {
    backgroundColor: 'red',
    width: '100%',
    height: '30%',
  },
  green: {},
  gridItemFirst: {
    width: 250,
  },
  gridItem: {
    height: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  row: {
    display: 'flex',
    justifyContent: 'stretch',
    width: window.innerWidth - drawerWidth - 50,
    borderBottom: '1px solid gray',
  },
  bordered: {
    borderRight: '1px solid gray',
  },
  mobileRow: {
    display: 'flex',
    borderBottom: '1px solid gray',
    width: window.innerWidth - 50,
  },
}))

export default function StatusDashboard() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()
  const status = useSelector(getStatusDashboard)
  const [endTime] = useState(new Date().getTime())
  const [detailsIncidentNumber, setDetailsIncidentNumber] = useState('')
  const [incidentDialogOpened, setIncidentDialogOpened] = useState(false)
  const fromTime = endTime - 1000 * 60 * 60 * 24
  const byName = new Map<string, Incident[]>()
  const st = status || { opened: [], closed: [] }
  st.closed.forEach((inc: Incident) => {
    let named: Incident[] = byName.get(inc.Name) || []
    named.push(inc)
    byName.set(inc.Name, named)
  })
  st.opened.forEach((inc: Incident) => {
    let named: Incident[] = byName.get(inc.Name) || []
    named.push(inc)
    byName.set(inc.Name, named)
  })
  let columns = 0
  for (let i = fromTime; i < endTime; i = i + 1000 * 60 * 5) {
    columns++
  }
  const openModal = (incidentNumber: string) => {
    setDetailsIncidentNumber(incidentNumber)
    setIncidentDialogOpened(true)
  }
  const columnWidth = isMobile
    ? (window.innerWidth - 300) / columns
    : (window.innerWidth - 550) / columns
  const keys: string[] = []
  const divsByName = new Map<string, JSX.Element[]>()
  byName.forEach((value: Incident[], key: string) => {
    keys.push(key)
    const divs = []
    const increment = 1000 * 60 * 5 // 5 min
    for (let i = fromTime; i < endTime; i = i + increment) {
      const minutes = (i - fromTime) / (1000 * 60)
      let incidentNumber = ''
      value.forEach(inc => {
        const start = Date.parse(inc.IncidentTime)
        const end = Date.parse(inc.StoppedTime)
        if (start <= i && inc.IsActive) {
          incidentNumber = inc.Number
        } else if (end >= i && end <= i + increment) {
          incidentNumber = inc.Number
        } else if (start >= i && start <= i + increment) {
          incidentNumber = inc.Number
        } else if (start <= i && end >= i) {
          incidentNumber = inc.Number
        }
      })
      divs.push(
        <div
          style={{ width: columnWidth }}
          className={classnames(classes.gridItem, { [classes.bordered]: minutes % 12 === 0 })}
          key={'' + i}
        >
          <div
            className={incidentNumber ? classes.orange : classes.green}
            onClick={() => {
              if (incidentNumber) {
                openModal(incidentNumber)
              }
            }}
          ></div>
        </div>
      )
    }
    divsByName.set(key, divs)
  })
  const detailedIncident: Incident = detailsIncidentNumber
    ? st.closed.concat(st.opened).filter((inc: Incident) => inc.Number === detailsIncidentNumber)[0]
    : {}

  const jsonViewer = (detailedIncident: Incident) => {
    return (
      <ReactJson
        src={{
          name: detailedIncident.Name,
          start: moment(Date.parse(detailedIncident.IncidentTime)).format('LLLL'),
          end: moment(Date.parse(detailedIncident.StoppedTime)).format('LLLL'),
        }}
      />
    )
  }
  return (
    <Load path={Data.StatusDashboard} params={{ from: fromTime, to: endTime }}>
        {detailedIncident && detailedIncident.IsActive && (
            <ActionDialog
              isOpened={incidentDialogOpened}
              handleClose={() => {setIncidentDialogOpened(false)}}
              action={Actions.CloseIncident}
              getFormData={() => {
                return {id: detailedIncident.ID, from: fromTime, to: endTime}
              }}
            >
              <DialogTitle id="view-incident-details-dialog-title">Close Incident</DialogTitle>
              <DialogContent>
                {jsonViewer(detailedIncident)}
              </DialogContent>
            </ActionDialog>
        )}
        {detailedIncident && !detailedIncident.IsActive && (
          <Modal
            className={classes.modal}
            open={detailsIncidentNumber !== ''}
            onClose={() => setDetailsIncidentNumber('')}
          >
            <div className={classes.paper}>
              {jsonViewer(detailedIncident)}
            </div>
        </Modal>
        )}
      {status && (
        <Panel header="Status dashboard: last 24 hours">
          <div className={classes.grid}>
            {keys.sort().map((key: string) => {
              return (
                <div className={isMobile ? classes.mobileRow : classes.row} key={key}>
                  <div className={classes.gridItemFirst}>{key}</div>
                  {divsByName.get(key)}
                </div>
              )
            })}
          </div>
        </Panel>
      )}
    </Load>
  )
}
