import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Load from '../../components/Actions/Load'
import { Data } from '../../state/app/types'
import { getBusDetails } from '../../state/internal-admin/selectors'
import { useSelector } from 'react-redux'
import { DataGrid } from '@material-ui/data-grid'
import Panel from '../../components/Panel/Panel'
import LaunchIcon from '@material-ui/icons/Launch'
import { BUS_TYPE } from '../../state/internal-admin/types'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function BusDetails({ bus }) {
  console.log('BUS', bus)
  const classes = useStyles()
  const busDetails = useSelector(getBusDetails) //list of domains for the bus
  const columns = [
    { field: 'urn', headerName: 'URN', width: 300 },
    { field: 'timezone', headerName: 'TimeZone', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 180,
      sortable: false,
      renderCell: params => {
        return (
          <a
            rel="noreferrer"
            href={`/domain-management/${params.getValue(params.id, 'urn')}/hl-troubleshooting`}
            target="_blank"
            className={classes.centered}
          >
            <div>Manage domain</div>
            <LaunchIcon />
          </a>
        )
      },
    },
  ]

  const rows = (busDetails || []).map(domain => {
    return { id: domain.URN, urn: domain.URN, timezone: domain.Timezone, actions: '' }
  })
  return (
    <Load path={Data.BusDetails} params={{ bus: bus._id }}>
      <Panel header="Domains in the bus">
        <div style={{ height: 750, width: "100%" }}>
          <DataGrid rows={rows} columns={columns} pageSize={15} />
        </div>
      </Panel>
    </Load>
  )
}

BusDetails.propTypes = {
  bus: PropTypes.instanceOf(BUS_TYPE),
}
