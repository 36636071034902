import React, { useState } from 'react'
import SideBar from '../../components/Authentication/Layout/SideBar'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import ListIcon from '@material-ui/icons/List'
import RowingIcon from '@material-ui/icons/Rowing'
import SchoolIcon from '@material-ui/icons/School'
import HandymanIcon from '@mui/icons-material/Handyman'
import DataUsageIcon from '@mui/icons-material/DataUsage'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import FlagIcon from '@mui/icons-material/Flag'

const items = [
  {
    title: 'Domain Summary',
    path: '/summary',
    id: 'summary',
    icon: <SchoolIcon />,
  },
  {
    title: 'Digital Backpack',
    path: '/backpack',
    id: 'backpack',
    icon: <LocalLibraryIcon />,
  },
  {
    title: 'Internal',
    type: 'divider',
    id: 'internal-divider',
  },
  {
    title: 'Tasks',
    path: '/tasks',
    id: 'tasks',
    icon: <ListIcon />,
  },
  {
    title: 'Activity',
    path: '/activity',
    id: 'activity',
    icon: <RowingIcon />,
  },
  {
    title: 'Highlights Troubleshooting',
    path: '/hl-troubleshooting',
    id: 'hl-troubleshooting',
    icon: <ReportProblemIcon />,
  },
  {
    title: 'TD Tools',
    path: '/td-tools',
    id: 'td-tools',
    icon: <HandymanIcon />,
  },
  {
    title: 'Reporting Data',
    path: '/reporting-data',
    id: 'reporting-data',
    icon: <DataUsageIcon />,
  },
  {
    title: 'Feature Flags',
    path: '/feature-flags',
    id: 'feature-flags',
    icon: <FlagIcon />,
  },
]

export default function DomainManagementNavigation({ path }) {
  const [open, setOpen] = useState(true)
  const { id } = useParams()

  return (
    <SideBar
      open={open}
      setOpen={setOpen}
      selected={id}
      items={items.map(i => ({ ...i, path: path + i.path }))}
    />
  )
}

DomainManagementNavigation.propTypes = {
  path: PropTypes.string,
}
