import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Panel from '../../components/Panel/Panel'
import Load from '../../components/Actions/Load'
import TimePicker, { DEFAULT_TIMEZONE } from '../../components/Actions/TimePicker'
import { Data } from '../../state/app/types'
import { useSelector } from 'react-redux'
import {
  getSelectedUser,
  getTaskGroupParent,
  getTaskGroupChildren,
} from '../../state/user-management/selectors'
import { getDomain } from '../../state/domain-management/selectors'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Modal from '@material-ui/core/Modal'
import ReactJson from 'react-json-view'
import _ from 'lodash'

import { Button, Paper } from '@material-ui/core'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  chipsContainer: {
    padding: theme.spacing(8),
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(8),
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 600,
    maxHeight: 600,
    overflow: 'scroll',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
  configuration: {
    display: 'flex',
  },
  slider: {
    maxWidth: 200,
  },
  table: {
    minWidth: 650,
  },
}))

type PageParams = {
  taskID: string
}

export default function UserSingleTaskGroup() {
  const classes = useStyles()
  const user = useSelector(getSelectedUser) as any
  const domain = useSelector(getDomain)
  const parentTask = useSelector(getTaskGroupParent) as any
  const childTasks = useSelector(getTaskGroupChildren) as any
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [infoModalContent, setInfoModalContent] = useState({})
  const [timezone, setTimezone] = useState(DEFAULT_TIMEZONE)
  const { taskID } = useParams<PageParams>()

  return (
    <div className={classes.root}>
      <h2>User Tasks</h2>
      <Modal className={classes.modal} open={infoModalOpen} onClose={() => setInfoModalOpen(false)}>
        <div className={classes.paper}>
          <ReactJson src={infoModalContent} />
        </div>
      </Modal>
      <Panel
        header={`User Info: ${
          _.get(user, 'roles').indexOf('student') < 0 ? '' : 'STUDENT  '
        } ${_.get(user, 'primary_email')}`}
      >
        <ReactJson src={user} collapsed={true} style={{ width: '100%' }} />
        <a
          rel="noreferrer"
          href={`https://hugs.teacherdashboard.com/admin/_/#/main/user/urn/${_.get(
            user,
            'primary_email'
          )}/raw`}
          target="_blank"
        >
          Hugs user
        </a>
      </Panel>
      <Panel header={`Domain Info: ${_.get(domain, 'URN')}`}>
        <ReactJson src={domain} collapsed={true} style={{ width: '100%' }} />
        <a
          rel="noreferrer"
          href={`https://hugs.teacherdashboard.com/admin/_/#/main/group/urn/${_.get(
            domain,
            'URN'
          )}/raw`}
          target="_blank"
        >
          Hugs domain
        </a>
      </Panel>
      <TimePicker timezone={timezone} onTimezoneChange={setTimezone} />
      <Load
        path={Data.TaskGroup}
        params={{
          id: taskID,
        }}
      >
        {parentTask && (
          <Panel header="Primary Task">
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      {moment(parentTask.Sent).tz(timezone).format('HH:mm:ss')}
                    </TableCell>
                    <TableCell align="left">{parentTask.task.Name}</TableCell>
                    <TableCell align="left">{parentTask.Status}</TableCell>
                    <TableCell align="right">
                      {
                        <Button
                          onClick={() => {
                            setInfoModalContent(parentTask)
                            setInfoModalOpen(true)
                          }}
                        >
                          All Details
                        </Button>
                      }{' '}
                      {
                        <Button
                          onClick={() => {
                            setInfoModalContent(parentTask.Payload)
                            setInfoModalOpen(true)
                          }}
                        >
                          Payload
                        </Button>
                      }
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Panel>
        )}
        {childTasks && (
          <Panel header="Child Tasks">
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableBody>
                  {(childTasks || []).map((row: any, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="left">
                          {moment(row.Sent).tz(timezone).format('HH:mm:ss')}
                        </TableCell>
                        <TableCell align="left">{row.task.Name}</TableCell>
                        <TableCell align="left">{row.Status}</TableCell>
                        <TableCell align="right">
                          {
                            <Button
                              onClick={() => {
                                setInfoModalContent(row)
                                setInfoModalOpen(true)
                              }}
                            >
                              All Details
                            </Button>
                          }{' '}
                          {
                            <Button
                              onClick={() => {
                                setInfoModalContent(row.Payload)
                                setInfoModalOpen(true)
                              }}
                            >
                              Payload
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Panel>
        )}
      </Load>
    </div>
  )
}
