import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from 'react-router-dom'
import { isSideBarOpened } from '../../../state/ui/selectors'
import { useSelector, useDispatch } from 'react-redux'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { IconButton } from '@material-ui/core'
import { closeSideBar } from '../../../state/ui/actions'
import BackIcon from '@material-ui/icons/ArrowBack'
import { SideBarItem, SideBarTypes } from '../../../state/app/types'

const drawerWidth = 250

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  grow: {
    flexGrow: 1,
  },
  divider: {
    padding: theme.spacing(),
    borderBottom: '1px solid gray',
    borderTop: '1px solid gray',
    marginTop: theme.spacing() * 2,
    backgroundColor: theme.palette.background.default,
  },
}))

const SidebarDivider: React.FunctionComponent<{ title: string }> = ({ title }) => {
  const classes = useStyles()
  return <div className={classes.divider}>{title}</div>
}

interface Props {
  items: SideBarItem[]
  selected: String
}

const SideBar: React.FunctionComponent<Props> = ({ items, selected }) => {
  const classes = useStyles()
  const isOpened = useSelector(isSideBarOpened)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()

  return (
    <Drawer
      className={classes.drawer}
      variant={isMobile ? undefined : 'permanent'}
      classes={{
        paper: classes.drawerPaper,
      }}
      open={isOpened}
      onClose={() => dispatch(closeSideBar())}
    >
      <Toolbar>
        <div className={classes.grow} />
        <IconButton
          onClick={() => {
            dispatch(closeSideBar())
          }}
        >
          <BackIcon />
        </IconButton>
      </Toolbar>
      <div className={classes.drawerContainer}>
        <List>
          {items.map(i => {
            if (i.type === SideBarTypes.DIVIDER) {
              return <SidebarDivider title={i.title} key={i.id} />
            }
            return (
              <ListItem
                button
                key={i.id}
                selected={selected === i.id}
                component={Link}
                to={i.path}
                onClick={() => dispatch(closeSideBar())}
              >
                <ListItemIcon>{i.icon}</ListItemIcon>
                <ListItemText primary={i.title} />
              </ListItem>
            )
          })}
        </List>
      </div>
    </Drawer>
  )
}

export default SideBar
