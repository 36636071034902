import axios from 'axios'
import { getToken } from '../auth/handleJWT'

const updateUserEmail = async (oldEmail: string, newEmail: string) => {
  const { access_token } = await getToken()
  const url =
    process.env.REACT_APP_BUILD_ENV === 'test'
      ? 'https://workspace-api-test.teacherdashboard.com/admin/migration/update-user-email'
      : 'https://workspace-api.teacherdashboard.com/admin/migration/update-user-email'

  const data = {
    oldEmail,
    newEmail,
  }
  const config = {
    headers: { Authorization: access_token },
  }

  return await axios.post(url, data, config)
}

export { updateUserEmail }
