import axios from 'axios'
import { getToken } from '../auth/handleJWT'

const UndeleteWorkspaceByUser = async (user: string) => {
  const { access_token } = await getToken()
  const url =
  process.env.REACT_APP_BUILD_ENV === 'test'
  ? 'https://workspace-api-test.teacherdashboard.com/admin/deleted-workspaces/user/' + user
  : 'https://workspace-api.teacherdashboard.com/admin/deleted-workspaces/user/' + user

  const config = {
    headers: { Authorization: access_token },
  }

  return await axios.get(url, config)
}

const UndeleteWorkspaceByClass = async (workspaceClass: string) => {
  const { access_token } = await getToken()
  const url =
  process.env.REACT_APP_BUILD_ENV === 'test'
  ? 'https://workspace-api-test.teacherdashboard.com/admin/deleted-workspaces/class/' +
  workspaceClass
  : 'https://workspace-api.teacherdashboard.com/admin/deleted-workspaces/class/' +
  workspaceClass

  const config = {
    headers: { Authorization: access_token },
  }

  return await axios.get(url, config)
}

const Undelete = async (workspaceID: string) => {
  const { access_token } = await getToken()
  const url =
    process.env.REACT_APP_BUILD_ENV === 'test'
      ? 'https://workspace-api-test.teacherdashboard.com/admin/undelete/' + workspaceID
      : 'https://workspace-api.teacherdashboard.com/admin/undelete/' + workspaceID
  const data = {}
  const config = {
    headers: { Authorization: access_token },
  }

  return await axios.post(url, data, config)
}

export { UndeleteWorkspaceByClass, UndeleteWorkspaceByUser, Undelete }
