import actionTypes from '../actionTypes'
import { Data, Actions } from './types'
import { getDomainInfo, getCurators, addCurator } from '../../apiCalls/domains'
import { LoadFunc } from '../../apiCalls/types'
import {
  getUserInfo,
  getExtensionLogs,
  getOldExtensionLogs,
  getStudentClasses,
  getSessionStateChangesLogs,
  getUserTasks,
  getTaskGroup,
} from '../../apiCalls/users'

import {
  getBusList,
  moveDomains,
  getBusDetails,
  moveOneDomain,
  getStatusDashboard,
  closeIncident,
  getClassInfo,
} from '../../apiCalls/internal-admin'
import { AppDispatch, RootState } from '../store'

const loadFunctions = {
  [Data.Domain]: getDomainInfo,
  [Data.Buses]: getBusList,
  [Data.BusDetails]: getBusDetails,
  [Data.DomainDigitalBackpackCurators]: getCurators,
  [Data.User]: getUserInfo,
  [Data.ExtensionLogs]: getExtensionLogs,
  [Data.OldExtensionLogs]: getOldExtensionLogs,
  [Data.StudentClasses]: getStudentClasses,
  [Data.SessionStateChanges]: getSessionStateChangesLogs,
  [Data.UserTasks]: getUserTasks,
  [Data.TaskGroup]: getTaskGroup,
  [Data.StatusDashboard]: getStatusDashboard,
  [Data.ClassInfo]: getClassInfo,
}

function getLoadFunction<ParamsType, ResponseType>(t: Data): LoadFunc<ParamsType, ResponseType> {
  return loadFunctions[t] as LoadFunc<ParamsType, ResponseType>
}

async function _loadInfo<ParamsType, ResponseType>(
  path: Data,
  params: ParamsType,
  dispatch: AppDispatch,
  state: RootState
) {

  dispatch({ type: actionTypes.APP_INFO_PENDING, path })

  await getLoadFunction<ParamsType, ResponseType>(path)(params)
    .then((response: ResponseType) => {
      dispatch({
        type: actionTypes.APP_INFO_SUCCESS,
        payload: response,
        path,
      })
    })
    .catch(error => {
      console.log('Data load error:', error)
      dispatch({
        error,
        type: actionTypes.APP_INFO_ERROR,
        path,
        isAccessError: error && error.status === 403,
      })
    })
}

export const clearPath = (path: string) => {
  return {
    type: actionTypes.APP_INFO_CLEAR,
    path,
  }
}

interface ActionConfig {
  reloadPath: Data
  actionFunc: LoadFunc<any, any>
}

const actionCloseIncidentConfig: ActionConfig = {
  reloadPath: Data.StatusDashboard,
  actionFunc: closeIncident,
}
const actionMoveDomainsConfig: ActionConfig = {
  reloadPath: Data.Buses,
  actionFunc: moveDomains,
}
const actionMoveDomainConfig: ActionConfig = {
  reloadPath: Data.Domain,
  actionFunc: moveOneDomain,
}
const actionAddBackpackCurator: ActionConfig = {
  reloadPath: Data.DomainDigitalBackpackCurators,
  actionFunc: addCurator,
}

const actionsConfig = {
  [Actions.MoveDomains]: actionMoveDomainsConfig,
  [Actions.MoveOneDomain]: actionMoveDomainConfig,
  [Actions.AddBackPackCurator]: actionAddBackpackCurator,
  [Actions.CloseIncident]: actionCloseIncidentConfig,
}

function getActionConfig<ParamsType, ResponseType>(
  action: Actions
): LoadFunc<ParamsType, ResponseType> {
  return actionsConfig[action].actionFunc as LoadFunc<ParamsType, ResponseType>
}

export function doAction<ParamsType, ResponseType>(
  action: Actions,
  params: ParamsType
): (dispatch: AppDispatch, getState: () => RootState) => Promise<ResponseType> {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    return await getActionConfig<ParamsType, ResponseType>(action)(params).then(
      async (response: ResponseType) => {
        if (actionsConfig[action].reloadPath) {
          await _loadInfo(actionsConfig[action].reloadPath, params, dispatch, state)
        }
        return response
      }
    )
  }
}

export function loadInfo<ParamsType>(
  path: Data,
  params: ParamsType
): (dispatch: AppDispatch, getState: () => RootState) => void {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    await _loadInfo(path, params, dispatch, state)
  }
}
