import actionTypes from '../actionTypes'
import _ from 'lodash'

const INIT_STATE = {}

const appReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.APP_INFO_PENDING:
      return {
        ..._.set(_.cloneDeep(state), action.path, { data: null, isLoading: true, isError: false }),
      }
    case actionTypes.APP_INFO_SUCCESS:
      return {
        ..._.set(_.cloneDeep(state), action.path, {
          data: action.payload,
          isLoading: false,
          isError: false,
        }),
      }
    case actionTypes.APP_INFO_ERROR:
      return {
        ..._.set(_.cloneDeep(state), action.path, {
          data: null,
          isLoading: false,
          isError: true,
          isAccessError: !!action.isAccessError,
        }),
      }
    case actionTypes.APP_INFO_CLEAR:
      return {
        ..._.set(_.cloneDeep(state), action.path, null),
      }
    default:
      return state
  }
}

export default appReducer
