import React, { useState, ChangeEvent, MouseEvent, SyntheticEvent, FocusEvent } from 'react'
import './NewTaskCopy.css'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { DatePicker } from 'antd'
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@mui/material/Autocomplete'
import { Input, TextField } from '@mui/material'
import { Modal, Button as AntdButton, Table } from 'antd'
import { requestNewTaskCopy, getCollectionsList } from '../../../apiCalls/new-task-copy'
import { useHistory } from 'react-router-dom'
import { Checkbox } from 'antd'

import type { Moment } from 'moment'
import type { CollectionItem } from '../../../apiCalls/new-task-copy'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import _ from 'lodash'

const CheckboxGroup = Checkbox.Group

export default function NewTaskCopy() {
  const WS = 'WS'
  const RESOURCES = 'Resources'

  const copyWsOrResourceOption = [WS, RESOURCES]
  const defaultCopyWsOrResourceOption: CheckboxValueType[] = [WS, RESOURCES]

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [sourceDomain, setSourceDomain] = useState<string>('')
  const [sourceCollectionName, setSourceCollectionName] = useState<string | null>(null)
  const [sourceCollectionID, setSourceCollectionID] = useState<string | null>(null)
  const [targetDomain, setTargetDomain] = useState<string>('')
  const [targetCollectionName, setTargetCollectionName] = useState<string | null>(null)
  const [targetCollectionID, setTargetCollectionID] = useState<string | null>(null)
  const [authorizer, setAuthorizer] = useState<string>('')
  const [authorizedDate, setAuthorizedDate] = useState<Moment | null>(null)
  const [modalText, setModalText] = useState<string | null>(null)
  const [targetOwner, setTargetOwner] = useState<string>('')

  const [copyWsOrResourceCheckedList, setCopyWsOrResourceCheckedList] = useState<
    CheckboxValueType[]
  >(defaultCopyWsOrResourceOption)

  const history = useHistory()

  const [sourceCollectionsList, setSourceCollectionsList] = useState<CollectionItem[]>([])
  const [targetCollectionsList, setTargetCollectionsList] = useState<CollectionItem[]>([])

  const [allowDups, setAllowDups] = useState<boolean>(false)

  const REQUIRED_FIELD = [
    'Source Domain',
    'Source Collection',
    'Target Domain',
    'Target Collection',
    'Copy WS or Resources',
  ]

  const REQUIRED_FIELD_FOR_RESOURCES = ['Authorizer', 'Date']

  const REQUIRED_FIELD_FOR_WS = ['Target Owner', 'allowDups']

  const ALL_ROWS_DATA: { [fields: string]: any } = {
    'Source Domain': sourceDomain,
    'Source Collection': sourceCollectionName,
    'Target Domain': targetDomain,
    'Target Collection': targetCollectionName,
    'Target Owner': targetOwner,
    Authorizer: authorizer,
    Date: authorizedDate,
    allowDups: allowDups.toString(),
    'Copy WS or Resources': copyWsOrResourceCheckedList,
  }

  const onSourceCollection = (
    event: SyntheticEvent<Element, Event>,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    setSourceCollectionName(value)
    sourceCollectionsList.forEach(item => {
      if (item.name === value) {
        setSourceCollectionID(item.id)
      }
    })
  }

  const onTargetCollection = (
    event: SyntheticEvent<Element, Event>,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    setTargetCollectionName(value)
    targetCollectionsList.forEach(item => {
      if (item.name === value) {
        setTargetCollectionID(item.id)
      }
    })
  }

  const showSubmitModal = () => {
    setModalText('Please confirm the all information below')
    setIsModalVisible(true)
  }

  const onSubmitClick = async (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    setConfirmLoading(true)
    try {
      await requestNewTaskCopy(
        {
          SourceDomain: sourceDomain as string,
          SourceCollectionID: sourceCollectionID as string,
          TargetDomain: targetDomain as string,
          TargetCollectionID: targetCollectionID as string,
          Authorisor: authorizer,
          AuthoriseTime: authorizedDate?.startOf('day').format(),
          TargetOwner: targetOwner,
          AllowDups: allowDups,
          CopyResourceTypes: copyWsOrResourceCheckedList,
        }
      )
      alert('The task has been submitted successfully')
      history.push('/internal-admin/bulk-copy')
    } catch (e) {
      alert('something wrong')
      console.log(e) // for us to trace err
    } finally {
      setConfirmLoading(false)
      setIsModalVisible(false)
    }
  }

  const checkAllRequiredValue = () => {
    for (const field of REQUIRED_FIELD) {
      if (ALL_ROWS_DATA[field] === null) {
        return true
      }
    }
    if (copyWsOrResourceCheckedList.includes(WS)) {
      for (const field of REQUIRED_FIELD_FOR_WS) {
        if (ALL_ROWS_DATA[field] === null) {
          return true
        }
      }
    }
    if (copyWsOrResourceCheckedList.includes(RESOURCES)) {
      for (const field of REQUIRED_FIELD_FOR_RESOURCES) {
        if (ALL_ROWS_DATA[field] === null) {
          return true
        }
      }
    }
    return false
  }

  const RenderCopyWSSourceSelection = (
    <Card className="new-task-copy__root__card">
      <CardContent>
        <Typography variant="h5" component="div" sx={{ paddingBottom: 2 }}>
          Copy WS, Resources
        </Typography>
        <>
          <CheckboxGroup
            options={copyWsOrResourceOption}
            value={copyWsOrResourceCheckedList}
            onChange={(list: CheckboxValueType[]) => {
              setCopyWsOrResourceCheckedList(list)
            }}
          />
        </>
      </CardContent>
    </Card>
  )

  const RenderSourceDomain = (
    <Card className="new-task-copy__root__card">
      <CardContent>
        <Typography variant="h5" component="div" sx={{ paddingBottom: 2 }}>
          Source Domain
        </Typography>
        <Input
          placeholder="source domain"
          className="new-task-copy__root__input-width"
          value={sourceDomain}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setSourceDomain(event.target.value)
          }}
          onBlur={async (evt: any) => {
            setSourceDomain(_.toLower(sourceDomain as string))
            if (sourceDomain && sourceDomain.length > 0) {
              setSourceCollectionsList(
                await getCollectionsList(_.toLower(sourceDomain as string))
              )
            }
          }}
        />
      </CardContent>
    </Card>
  )

  const RenderSourceCollection = (
    <Card className="new-task-copy__root__card">
      <CardContent>
        <Typography variant="h5" component="div" sx={{ paddingBottom: 2 }}>
          Source Collection
        </Typography>
        <Autocomplete
          disablePortal
          id="source_collection_combo_box"
          options={
            sourceCollectionsList === null ? [] : sourceCollectionsList.map(item => item.name)
          }
          className="new-task-copy__root__input-width"
          renderInput={params => <TextField {...params} label="Collection" />}
          onChange={onSourceCollection}
        />
      </CardContent>
    </Card>
  )

  const RenderTargetDomain = (
    <Card className="new-task-copy__root__card">
      <CardContent>
        <Typography variant="h5" component="div" sx={{ paddingBottom: 2 }}>
          Target Domain
        </Typography>
        <Input
          placeholder="target domain"
          className="new-task-copy__root__input-width"
          value={targetDomain}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setTargetDomain(event.target.value)
          }}
          onBlur={async (evt: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setTargetDomain(_.toLower(targetDomain))
            if (targetDomain && targetDomain.length > 0) {
              setTargetCollectionsList(await getCollectionsList( _.toLower(targetDomain)))
            }
          }}
        />
      </CardContent>
    </Card>
  )

  const RenderTargetCollection = (
    <Card className="new-task-copy__root__card">
      <CardContent>
        <Typography variant="h5" component="div" sx={{ paddingBottom: 2 }}>
          Target Collection
        </Typography>
        <Autocomplete
          disablePortal
          id="target_collection_combo_box"
          options={
            targetCollectionsList === null ? [] : targetCollectionsList.map(item => item.name)
          }
          className="new-task-copy__root__input-width"
          renderInput={params => <TextField {...params} label="Collection" />}
          onChange={onTargetCollection}
        />
      </CardContent>
    </Card>
  )

  const RenderAuthorize = (
    <Card className="new-task-copy__root__card">
      <CardContent>
        <Typography variant="h5" component="div">
          If copying resources, customer email whoever authorized to copy
        </Typography>
        <div className="new-task-copy__root__card__content">
          <Input
            value={authorizer}
            placeholder="your answer"
            className="new-task-copy__root__input-width"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setAuthorizer(event.target.value)
            }}
            onBlur={(evt: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setAuthorizer(_.toLower(authorizer))
            }}
          />
        </div>
      </CardContent>
    </Card>
  )

  const RenderTargetOwner = (
    <Card className="new-task-copy__root__card">
      <CardContent>
        <Typography variant="h5" component="div">
          If copying WS, Target Account - Existing User account in Target Domain to own the
          Workspace
        </Typography>
        <div className="new-task-copy__root__card__content">
          <Input
            value={targetOwner}
            placeholder="your answer"
            className="new-task-copy__root__input-width"
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setTargetOwner(event.target.value)
            }}
            onBlur={(event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setTargetOwner(_.toLower(event.target.value))
            }}
          />
        </div>
      </CardContent>
    </Card>
  )

  const RenderDatePicker = (
    <Card className="new-task-copy__root__card">
      <CardContent>
        <Typography variant="h5" component="div">
          If copying resources, the date of authorization received by the customer
        </Typography>
        <div className="new-task-copy__root__card__content">
          <DatePicker
            onChange={(date, dateString) => {
              setAuthorizedDate(date)
            }}
          />
        </div>
      </CardContent>
    </Card>
  )

  const RenderFinalCheckTable = () => {
    const dataSource = []
    for (const [key, val] of Object.entries(ALL_ROWS_DATA)) {
      switch (key) {
        case 'Date':
          dataSource.push({
            key: key,
            name: key,
            value:
              val !== null
                ? (val as Moment)?.format('MMM Do YYYY')
                : copyWsOrResourceCheckedList.includes(RESOURCES)
                ? '*required'
                : 'Not Required',
          })
          break
        case 'Copy WS or Resources':
          dataSource.push({
            key: key,
            name: key,
            value: val.length > 0 ? val.toString() : '*required',
          })
          break
        case 'Authorizer':
          dataSource.push({
            key: key,
            name: key,
            value:
              val !== ''
                ? val
                : copyWsOrResourceCheckedList.includes(RESOURCES)
                ? '*required'
                : 'Not Required',
          })
          break
        case 'Target Owner':
          dataSource.push({
            key: key,
            name: key,
            value:
              val !== ''
                ? val
                : copyWsOrResourceCheckedList.includes(WS)
                ? '*required'
                : 'Not Required',
          })
          break
        default:
          dataSource.push({
            key: key,
            name: key,
            value: val === null || val === '' ? '*required' : val,
          })
      }
    }

    return (
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: text => (
              <span style={{ color: text === '*required' ? 'red' : 'black' }}>{text}</span>
            ),
          },
        ]}
      />
    )
  }

  const RenderAllowDups = (
    <Card className="new-task-copy__root__card">
      <CardContent>
        <div className="new-task-copy__root__card__content">
          <Checkbox
            onChange={(e: CheckboxChangeEvent) => {
              setAllowDups(e.target.checked)
            }}
          >
            Allow Duplicate
          </Checkbox>
        </div>
      </CardContent>
    </Card>
  )

  const RenderBtnGroups = (
    <div className="new-task-copy__root_submit-btn">
      <AntdButton type="primary" onClick={showSubmitModal}>
        Submit
      </AntdButton>
      <Modal
        title="Confirm"
        visible={isModalVisible}
        okButtonProps={{ disabled: checkAllRequiredValue() }}
        onOk={onSubmitClick}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        confirmLoading={confirmLoading}
      >
        <p>{modalText}</p>
        <div>{RenderFinalCheckTable()}</div>
      </Modal>
    </div>
  )

  return (
    <div className="new-task-copy__root">
      {RenderCopyWSSourceSelection}
      {RenderSourceDomain}
      {RenderSourceCollection}
      {RenderTargetDomain}
      {RenderTargetCollection}
      {RenderAuthorize}
      {RenderTargetOwner}
      {RenderDatePicker}
      {RenderAllowDups}
      {RenderBtnGroups}
    </div>
  )
}
