import React, { ChangeEvent, useState } from 'react'
import { Button, Card, Input, notification, Table } from 'antd'
import './ListGroupMembers.scss'
import { getDRMgroup } from '../../../apiCalls/ListGroupMembers'

interface DataType {
  key: string
  name: string
}

export default function ListGroupMembers() {
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  const [searchDomain, setSearchDomain] = useState<string>('')
  const [searchEmail, setSearchEmail] = useState<string>('')
  const [userList, setUserList] = useState<DataType[]>([])

  const USER_LIST_COLUMN = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <p>{text}</p>,
    },
  ]

  const onSearch = async () => {
    setIsSearchLoading(true)
    if (searchDomain !== '' && searchEmail !== '') {
      try {
        const result = await getDRMgroup(searchDomain, searchEmail)
        setUserList(
          result.map((record: string, index: number) => ({ key: index.toString(), name: record }))
        )
      } catch (err) {
        alert(err)
      } finally {
        setIsSearchLoading(false)
      }
    } else {
      notification.warning({
        message: 'Notification',
        description: 'Please, check the domain and email again',
      })
    }
    setIsSearchLoading(false)
  }

  const RenderForm = (
    <div className="inputGroup">
      <div>
        <label htmlFor="domain">Domain</label>
        <Input
          id="domain"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchDomain(e.target.value)}
          size="large"
        />
      </div>
      <div>
        <label htmlFor="email">Group Email</label>
        <Input
          id="email"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchEmail(e.target.value)}
          size="large"
        />
      </div>

      <Button type="primary" size="large" loading={isSearchLoading} onClick={onSearch}>
        Search
      </Button>
    </div>
  )
  const RenderResultList = (
    <div className="list">
      <Table columns={USER_LIST_COLUMN} dataSource={userList} />
    </div>
  )

  return (
    <Card className="card">
      <div className="title">
        <h1>List Group Members for SDDB Lite Domain</h1>
        <p>
          This tool is for domains which have installed the SDDB Lite app only. It will list users
          from the specified group. You can use this to list Digital Resource Managers Group for a
          domain
        </p>
      </div>

      {RenderForm}

      {RenderResultList}
    </Card>
  )
}
