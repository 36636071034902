import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import NavBar from '../../components/Authentication/Layout/NavBar'
import { Link } from 'react-router-dom'
import { TextField } from '@material-ui/core'
import Panel from '../../components/Panel/Panel'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { useSelector } from 'react-redux'
import { isUserLoggedIn } from '../../state/user/selectors'
import GoogleLogin from '../../components/Authentication/GoogleLogin'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  select: {},
  page: {
    maxWidth: 1000,
    width: '100%',
    margin: 80,
  },
  button: {
    padding: 10,
    margin: 10,
  },
  button2: {
    flexGrow: 1,
  },
  text: {
    maxWidth: 400,
  },
}))

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
}))

export default function LandingPage() {
  const [isUserSignedIn, setIsUserSignedIn] = useState(false)
  const hasSession = useSelector(isUserLoggedIn)
  const classes = useStyles()

  const history = useHistory()
  const handleEnterInput = (e, type) => {
    if (e.key === 'Enter') {
    history.push(`/${type}-management/${e.target.value}/summary`)
    }
  }

  useEffect(() => {
    const sessionDetails = JSON.parse(localStorage.getItem('session_details'))
    const isUserSignedIn = sessionDetails?.user_signedIn || false
    setIsUserSignedIn(isUserSignedIn)
  }, [hasSession])

  return (
    <div className={classes.root}>
      {isUserSignedIn && <NavBar />}
      <div className={classes.page}>
        <Toolbar />
        <GoogleLogin renderSignOut={isUserSignedIn} />
        {isUserSignedIn && (
          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <Item>
                <Panel header="Internal Management" elevation={0}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    label="domain"
                    size="large"
                    to="/internal-admin/buses"
                    component={Link}
                  >
                    Go To Internal Management
                  </Button>
                </Panel>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Panel header="Select domain" elevation={0}>
                  <div className={classes.text}>
                    <TextField
                      helperText="Please enter a domain name here (e.g. xxx.zawadzki.co.nz)"
                      onKeyPress={e => handleEnterInput(e, 'domain')}
                    ></TextField>
                  </div>
                </Panel>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Panel header="Select user" elevation={0}>
                  <div className={classes.text}>
                    <TextField
                      helperText="Please enter a user id or email here (e.g. student1@zawadzki.co.nz)"
                      onKeyPress={e => handleEnterInput(e, 'user')}
                    ></TextField>
                  </div>
                </Panel>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Panel header="Select class" elevation={0}>
                  <div className={classes.text}>
                    <TextField
                      helperText="Please enter a class urn here (e.g. room-123@zawadzki.co.nz)"
                      onKeyPress={e => handleEnterInput(e, 'class')}
                    ></TextField>
                  </div>
                </Panel>
              </Item>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  )
}
