import 'whatwg-fetch'
import {
  BusDetailsRequest,
  BusDetailsResponse,
  MoveDomainsRequest,
  MoveDomainsResponse,
  MoveDomainRequest,
  MoveDomainResponse,
  LoadFunc,
  GetStatusDashboardRequest,
  GetStatusDashboardResponse,
} from './types'

import { getToken } from '../auth/handleJWT'

export async function getBusList() {
  const { access_token } = await getToken()
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/admin/bus`
  const opts = {
    headers: { Authorization: access_token },
  }
  const response = await fetch(url, opts)
  if (!response.ok) {
    throw response
  }
  return await response.json()
}

export const getBusDetails: LoadFunc<BusDetailsRequest, BusDetailsResponse> = async (
  params
) => {
  const { access_token } = await getToken()
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/admin/bus-details?bus=${params.bus}`
  const opts = {
    headers: { Authorization: access_token },
  }
  const response = await fetch(url, opts)
  if (!response.ok) {
    throw response
  }
  return await response.json()
}

export const moveDomains: LoadFunc<MoveDomainsRequest, MoveDomainsResponse> = async (
  params
) => {
  const { access_token } = await getToken()
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/admin/bus/move-bus`
  const opts = {
    method: 'POST',
    headers: { Authorization: access_token },
    body: JSON.stringify({
      From: params.source,
      To: params.destination,
      DomainsCount: params.domainsNumber,
    }),
  }
  const response = await fetch(url, opts)
  return await response.text()
}

export const moveOneDomain: LoadFunc<MoveDomainRequest, MoveDomainResponse> = async (
  params
) => {
  const { access_token } = await getToken()
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/admin/bus/move-domain`
  const opts = {
    method: 'POST',
    headers: { Authorization: access_token },
    body: JSON.stringify({
      Domain: params.domain,
      Destination: params.destination,
    }),
  }
  const response = await fetch(url, opts)
  return await response.text()
}

export const getStatusDashboard: LoadFunc<GetStatusDashboardRequest, GetStatusDashboardResponse> =
  async (params) => {
    const { access_token } = await getToken()
    const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/statusdashboard/status?from=${params.from}&to=${params.to}`
    const opts = {
      method: 'GET',
      headers: { Authorization: access_token },
    }
    const response = await fetch(url, opts)
    return await response.json()
  }

interface CloseIncidentRequest {
  id: string
}

type CloseIncidentResponse = string

export const closeIncident: LoadFunc<CloseIncidentRequest, CloseIncidentResponse> =
  async (params) => {
    const { access_token } = await getToken()
    const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/statusdashboard/close-incident/${params.id}`
    const opts = {
      method: 'PUT',
      headers: { Authorization: access_token },
    }
    const response = await fetch(url, opts)
    return await response.text()
  }

interface GetClassInfoRequest {
  class_urn : string
}

export type GetClassInfoResponse = {}

export const getClassInfo: LoadFunc<GetClassInfoRequest, GetClassInfoResponse> =
  async (params) => {
    const { access_token } = await getToken()
    const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/admin/class-info/${params.class_urn}`
    const opts = {
      method: 'GET',
      headers: { Authorization: access_token },
    }
    const response = await fetch(url, opts)
    return await response.json()
  }
