import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
  },
}))

export default function TasksManagement() {
  const classes = useStyles()
  console.log(classes.appBar) //placeholder for now
  return <div>Tasks</div>
}
