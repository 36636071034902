import axios from 'axios'
import { getToken } from '../auth/handleJWT'

const getDomainData = async (domain: string) => {
  const { access_token } = await getToken()
  const url =
  process.env.REACT_APP_BUILD_ENV === 'test'
  ? `https://api-test.hapara.com/admin/admin/domain?domain=${domain}`
  : `https://api.hapara.com/admin/admin/domain?domain=${domain}`

  const config = {
    headers: { Authorization: access_token },
  }

  return await axios.get(url, config)
}

const getPugsData = async (domain: string) => {
  const { access_token } = await getToken()
  const url =
    process.env.REACT_APP_BUILD_ENV === 'test'
      ? `https://pugs-test.teacherdashboard.com/v2/tools/auditinfo/${domain}`
      : `https://pugs.teacherdashboard.com/v2/tools/auditinfo/${domain}`

  const config = {
    headers: { Authorization: access_token },
  }

  return await axios.get(url, config)
}

export { getDomainData, getPugsData }
