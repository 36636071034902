import { axiosWithInterceptors } from './axios'

interface PageInfo {
  limit: number
  offset: number
}

interface Event {
  author: string
  coverurl: string
  description: string
  level: string[]
  license: string
  page: number
  path: string
  publisherid: string
  resourcetypes: string[]
  standards: string[]
  subjects: string[]
  time: string
  title: string
  type: string
  user: string
}
interface Doc {
  _id: string
  author: string
  coverurl: string
  created: string
  description: string
  domain: string
  events: Event[]
  filename: string
  gscname: string
  ispublic: boolean
  numpages: string
  publisherid: string
  pubstatus: string
  pubstatusmessage: string
  title: string
  version: number
  viewpath: string
}

interface GtxcelStatus {
  user: string
  time: string
  type: string
  status: string
  coverImage: string
  url: string
}
interface GTXcelRecord {
  doc: Doc
  gtxcelStatus: GtxcelStatus
}

const getGTXcelRecords = async (params: PageInfo, JwtToken: string) =>
  await axiosWithInterceptors.post<PageInfo, GTXcelRecord[]>('/library/hap-admin/getDocs', params, {
    headers: { Authorization: JwtToken },
  })


export { getGTXcelRecords }
export type { PageInfo, Doc, GtxcelStatus, Event, GTXcelRecord }
