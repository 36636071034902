import React, { useEffect } from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import NavBar from '../../components/Authentication/Layout/NavBar'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { useState } from 'react'
import { getToken } from '../../auth/handleJWT'

interface ParamsForIframe {
  token: String
  schoolCode: string
}
export default function UsageReport() {
  const [currentHJWT, setCurrentHJWT] = useState<string>('')

  useEffect(() => {
    getToken().then(token => {
      setCurrentHJWT(token.access_token)
    })
  }, [])

  let [paramsState, setParamsState] = useState<ParamsForIframe>({
    token: currentHJWT,
    schoolCode: '',
  })
  const paramsAsString = JSON.stringify({
    'ds96.jwt': paramsState.token,
    'ds96.school': paramsState.schoolCode,
  })
  const encodedParams = encodeURIComponent(paramsAsString)
  return (
    <div>
      <NavBar />
      <div>
        <Toolbar />
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="token"
            label="JWT token"
            variant="standard"
            onChange={e => setParamsState({ ...paramsState, token: e.target.value })}
          />
          <TextField
            id="schoolCode"
            label="schoolCode"
            variant="standard"
            onChange={e => setParamsState({ ...paramsState, schoolCode: e.target.value })}
          />
        </Box>

        <iframe
          style={{ height: '100vh', width: '100vw' }}
          title="Usage report"
          src={`https://lookerstudio.google.com/embed/reporting/f9379d17-13d7-467b-8dd1-8f39cced3b03/page/p_jipe81uxvc?params=${encodedParams}`}
        ></iframe>
      </div>
    </div>
  )
}
