import _ from 'lodash'

export const getData = path => state => _.get(state, `app.${path}.data`)

export const isLoading = path => state => _.get(state, `app.${path}.isLoading`)

export const isLoaded = path => state =>
  !_.get(state, `app.${path}.isLoading`) &&
  (_.get(state, `app.${path}.isError`) || _.get(state, `app.${path}.data`))

export const isError = path => state => _.get(state, `app.${path}.isError`)

export const isAccessError = path => state => _.get(state, `app.${path}.isAccessError`)

export const getQuery = state => _.get(state, `router.location.query`)
