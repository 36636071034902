import { useState } from 'react'
import { getDomain } from '../../../../state/domain-management/selectors'
import { useSelector } from 'react-redux'
import {
  Card,
  CardContent,
  Typography,
  Link,
  FormControl,
  InputAdornment,
  OutlinedInput,
  TextField,
  Button,
  Grid,
  Box,
  MenuItem,
  Select,
} from '@mui/material'

export default function SIS() {
  const domain = useSelector(getDomain)

  function Links() {
    return (
      <Card sx={{ m: 3 }}>
        <CardContent>
          <Link
            href={'https://td-admin.appspot.com/test/integrationConfig/-' + domain.urn}
            target="_blank"
          >
            SIS Configuration
          </Link>
          <br />
          <Link
            href={'https://td-admin.appspot.com/test/integration/viewer/' + domain.urn}
            target="_blank"
          >
            Browse Data
          </Link>
          <br />
          <Link
            href={'https://td-admin.appspot.com/util/sis/schools/' + domain.urn}
            target="_blank"
          >
            List Schools in SIS
          </Link>
        </CardContent>
      </Card>
    )
  }

  function Remapping() {
    const [sisColumnValue1, setSisColumnValue1] = useState('')
    const [sisColumnValue2, setSisColumnValue2] = useState('')
    const [sisColumnValue3, setSisColumnValue3] = useState('')
    const [sisDropdownOption, setSisDropdownOption] = useState(1)

    function handleSisUrlSubmit(): string {
      const url = 'https://td-admin.appspot.com/test/integrationConfig/'
      switch (sisDropdownOption) {
        case 1:
          return url + domain.urn + '/patch?val=$' + sisColumnValue1 + '&prop=format_schoolID'
        case 2:
          return (
            url +
            domain.urn +
            '/patch?val=$' +
            sisColumnValue1 +
            '-p$' +
            sisColumnValue2 +
            '&prop=format_mailbox'
          )
        case 3:
          return url + domain.urn + '/patch?val=$' + sisColumnValue1 + '&prop=format_teacher'
        case 4:
          return (
            url +
            domain.urn +
            '/patch?val={"Class Name*":"$' +
            sisColumnValue1 +
            '-$' +
            sisColumnValue2 +
            '","Folders*":"$' +
            sisColumnValue3 +
            '"}&prop=additionalClassFieldFormats&json=true'
          )
        case 5:
          return (
            url +
            domain.urn +
            '/patch?val={"Class Name*":"$' +
            sisColumnValue1 +
            '-$' +
            sisColumnValue2 +
            '"}&prop=additionalClassFieldFormats&json=true'
          )
        case 6:
          return (
            url +
            domain.urn +
            '/patch?val={"Folders*":"$' +
            sisColumnValue1 +
            '"}&prop=additionalClassFieldFormats&json=true'
          )
        default:
          return 'NO_VALUE'
      }
    }

    return (
      <Card sx={{ m: 3 }}>
        <Box sx={{ m: 3 }}>
          <FormControl fullWidth sx={{ pb: 3 }}>
            <Typography variant="h6">Custom SIS URL Remapping</Typography>
            <Select
              value={sisDropdownOption}
              onChange={(e: any) => setSisDropdownOption(e.target.value)}
            >
              <MenuItem value={1}>School Code/School ID - remapping URL</MenuItem>
              <MenuItem value={2}>Mailbox - remapping URL</MenuItem>
              <MenuItem value={3}>Teacher email - remapping URL</MenuItem>
              <MenuItem value={4}>Class and Folder name together - remapping URL</MenuItem>
              <MenuItem value={5}>Class name only - remapping URL</MenuItem>
              <MenuItem value={6}>Folder name only - remapping URL</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ pb: 3 }}>
            <TextField
              placeholder="Column Value 1"
              value={sisColumnValue1}
              onChange={(e: any) => setSisColumnValue1(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ pb: 3 }}>
            <TextField
              placeholder="Column Value 2"
              value={sisColumnValue2}
              disabled={
                sisDropdownOption === 1 || sisDropdownOption === 3 || sisDropdownOption === 6
              }
              onChange={(e: any) => setSisColumnValue2(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ pb: 3 }}>
            <TextField
              placeholder="Column Value 3"
              value={sisColumnValue3}
              disabled={
                sisDropdownOption === 1 ||
                sisDropdownOption === 2 ||
                sisDropdownOption === 3 ||
                sisDropdownOption === 5 ||
                sisDropdownOption === 6
              }
              onChange={(e: any) => setSisColumnValue3(e.target.value)}
            />
          </FormControl>
          <Button
            variant="contained"
            component={Link}
            href={handleSisUrlSubmit()}
            target="_blank"
            disabled={sisColumnValue1.length < 1}
            fullWidth
          >
            Submit
          </Button>
          <Typography variant="inherit" align="center">
            <b>WARNING: Don't do this for clever schools!</b>
          </Typography>
        </Box>
      </Card>
    )
  }

  function StudentExtraction() {
    const [studentExtractionValue, setStudentExtractionValue] = useState('')

    return (
      <Card sx={{ m: 3 }}>
        <CardContent>
          <Typography variant="h6">SIS Student Extraction - In Test Domain</Typography>
          <Box textAlign="center">
            <p>NB: This will overwrite the configuration for the test domain.</p>
            <p>Extracts for all schools and classes, if run for a non-DM test domain</p>
            <p>
              <b>Blanks domain info</b> for specified domain.
            </p>
            <Box>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ pb: 3 }}
              >
                <OutlinedInput
                  placeholder="E.g. SIS"
                  fullWidth
                  onChange={(e: any) => setStudentExtractionValue(e.target.value)}
                  endAdornment={<InputAdornment position="end">.zawadzki.co.nz</InputAdornment>}
                />
              </Grid>
              <Box textAlign="center">
                <Button
                  variant="contained"
                  disabled={studentExtractionValue.length < 1}
                  component={Link}
                  href={
                    'https://td-admin.appspot.com/test/integration/samples?host=' +
                    studentExtractionValue +
                    '&op=student&target=' +
                    domain.urn
                  }
                  target="_blank"
                  fullWidth
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    )
  }

  function ClassExtraction() {
    const [classExtractionValue, setClassExtractionValue] = useState('')

    return (
      <Card sx={{ m: 3 }}>
        <CardContent>
          <Typography variant="h6">SIS Class Extraction - In Test Domain</Typography>
          <Box textAlign="center">
            <p>NB: This will overwrite the configuration for the test domain.</p>
            <Box>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ pb: 3 }}
              >
                <OutlinedInput
                  placeholder="E.g. SIS"
                  onChange={(e: any) => setClassExtractionValue(e.target.value)}
                  endAdornment={<InputAdornment position="end">.zawadzki.co.nz</InputAdornment>}
                  fullWidth
                />
              </Grid>
              <Box textAlign="center">
                <Button
                  variant="contained"
                  disabled={classExtractionValue.length < 1}
                  component={Link}
                  href={
                    'https://td-admin.appspot.com/test/integration/samples?host=' +
                    classExtractionValue +
                    '&op=class&target=' +
                    domain.urn
                  }
                  target="_blank"
                  fullWidth
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card sx={{ m: 3 }}>
      <CardContent>
        <Typography variant="h2" align="center">
          SIS
        </Typography>
        <Links />
        <Remapping />
        <StudentExtraction />
        <ClassExtraction />
      </CardContent>
    </Card>
  )
}
