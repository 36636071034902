import actionTypes from '../actionTypes'

const INIT_STATE = {
  jwtInfo: null,
  jwtError: false,
  jwtLoading: false,
  jwtLoaded: false,
  userLoggedIn: false,
}

const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.APP_HJWT_PENDING:
      return {
        ...state,
        jwtLoading: true,
      }
    case actionTypes.APP_HJWT_SUCCESS:
      return {
        ...state,
        jwtLoading: false,
        jwtLoaded: true,
        userLoggedIn: true,
      }
    case actionTypes.APP_HJWT_ERROR:
      return {
        ...state,
        jwtLoading: false,
        jwtLoaded: false,
        jwtError: false,
        userLoggedIn: false,
      }
    case actionTypes.APP_HJWT_SIGNOUT:
      return {
        ...state,
        jwtLoading: false,
        jwtLoaded: false,
        jwtError: false,
        userLoggedIn: false,
      }

    default:
      return state
  }
}

export default userReducer
