import { useState } from 'react'
import {
  Typography,
  Box,
  FormControl,
  TextField,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
} from '@mui/material'
import { updateUserEmail } from '../../../apiCalls/update-user-email'

export default function UpdateEmail() {
  const [prettyResponse, setPrettyResponse] = useState('')
  const [data, setData] = useState([{}])
  const [operationData, setOperationData] = useState([{}])

  function Form() {
    const [oldEmail, setOldEmail] = useState('')
    const [newEmail, setNewEmail] = useState('')

    const handleUpdateUserEmailBtn = async () => {
      await updateUserEmail(oldEmail, newEmail).then(res => {
        setPrettyResponse(JSON.stringify(res, undefined, 2))

        const summaryDisplay = res.data.map((item: any) => {
          return {
            OldEmail: item?.Summary?.OldEmail,
            NewEmail: item?.Summary?.NewEmail,
            BoardsFound: item?.Summary?.BoardsFound,
          }
        })
        setData(summaryDisplay)

        res.data.map((item: any) => {
          let changes = item.Changes.map((items: any) => {
            return {
              ID: items?.ID,
              BoardID: items?.BoardID,
              Change: items?.Change,
              Result: items?.Result,
              Updates: items?.Updates,
            }
          })
          setOperationData(changes)

          return {
            changes,
          }
        })
      })
    }

    return (
      <Card sx={{ m: 3 }}>
        <CardContent sx={{ m: 3 }}>
          <Box>
            <Typography variant="h6" sx={{ pb: 2 }}>
              Update User Email
            </Typography>
            <Box sx={{mb: 2}}>
              <Alert severity="info" >
                Transfers a workspace to a new user via email at app.hapara.com.
              </Alert>
            </Box>
            <FormControl fullWidth sx={{ pb: 2 }}>
              <TextField
                placeholder="Old Email"
                value={oldEmail}
                onChange={(e: any) => setOldEmail(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={{ pb: 2 }}>
              <TextField
                placeholder="New Email"
                value={newEmail}
                onChange={(e: any) => setNewEmail(e.target.value)}
              />
            </FormControl>
            <Button
              variant="contained"
              onClick={handleUpdateUserEmailBtn}
              disabled={!(oldEmail && newEmail)}
            >
              Submit
            </Button>
          </Box>
        </CardContent>
      </Card>
    )
  }

  function Summary() {
    function handleOldEmail(oldEmail: any) {
      if (oldEmail === '') {
        return 'ERROR: Email does not exist or user does not have a workspace'
      } else {
        return oldEmail
      }
    }

    let position = 0
    return (
      <Card sx={{ m: 3 }}>
        <CardContent sx={{ m: 3 }}>
          <Box>
            <Typography variant="h6" sx={{ pb: 2 }}>
              Summary
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Old Email</TableCell>
                    <TableCell>New Email</TableCell>
                    <TableCell>Workspaces (Boards)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row: any) => (
                    <TableRow
                      key={(position += 1)}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {handleOldEmail(row.OldEmail)}
                      </TableCell>
                      <TableCell>{row.NewEmail}</TableCell>
                      <TableCell>Updated: {row.BoardsFound} Workspaces</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
    )
  }

  function Operations() {
    let position = 0

    return (
      <Card sx={{ m: 3 }}>
        <CardContent sx={{ m: 3 }}>
          <Box>
            <Typography variant="h6" sx={{ pb: 2 }}>
              Operations
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Board ID</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Updates</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {operationData.map((row: any) => (
                    <TableRow
                      key={(position += 1)}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{row.ID}</TableCell>
                      <TableCell>{row.BoardID}</TableCell>
                      <TableCell>{row.Change}</TableCell>
                      <TableCell>{row.Result}</TableCell>
                      <TableCell>{row.Updates}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
    )
  }

  function RawJSON() {
    return (
      <Card sx={{ m: 3 }}>
        <CardContent sx={{ m: 3 }}>
          <Typography variant="h6">Raw JSON</Typography>
          <pre>{prettyResponse}</pre>
        </CardContent>
      </Card>
    )
  }

  return (
    <Box sx={{ width: '75%' }}>
      <Form />
      <Summary />
      <Operations />
      <RawJSON />
    </Box>
  )
}
