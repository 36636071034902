import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Divider, Paper } from '@material-ui/core'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing() * 4,
    alignItems: 'stretch',
    flexGrow: 1,
  },
  header: {
    minHeight: 40,
    paddingTop: theme.spacing() * 2,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing() * 5,
  },
  divider: {
    marginBottom: theme.spacing() * 4,
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
  },
}))

export default function Panel({ header = '', children, minHeight, className = '', elevation }) {
  const classes = useStyles()

  return (
    <div className={classnames(classes.root, className)}>
      <Paper className={classes.paper} style={{ minHeight: minHeight }} elevation={elevation}>
        {header && (
          <div className={classes.header}>
            <Typography variant="subtitle1" component="h6">
              {header}
            </Typography>
          </div>
        )}
        {header && <Divider className={classes.divider} />}
        <div className={classes.centered}>{children}</div>
      </Paper>
    </div>
  )
}

Panel.propTypes = {
  header: PropTypes.node.isRequired,
  minHeight: PropTypes.number,
}

Panel.defaultProps = {
  minHeight: 100,
  elevation: 1,
}
