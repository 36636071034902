import React, { useState, useEffect } from 'react'
import { Card, CardContent, Box, Link, Grid, Button, Alert, CircularProgress } from '@mui/material'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { getDomain } from '../../../state/domain-management/selectors'

export default function DisplayFeatureFlags() {
  const [featureFlags, setFeatureFlags] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const domain = useSelector(getDomain)

  useEffect(() => {
    axios
      .get(`https://ff.hapara.com/ff/ui/domains/${domain.urn}`, { withCredentials: true })
      .then(res => {
        const featureFlagData = res.data.flags.map(items => {
          return {
            Enabled: items?.enabled,
            Name: items?.key,
          }
        })
        setFeatureFlags(previous => (previous = featureFlagData))
        setIsLoading(false)
      })
  }, [domain])

  let position = 0
  if (isLoading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    )
  } else if (!isLoading) {
    return (
      <Box sx={{ width: 1 }}>
        <Card sx={{ m: 3 }}>
          <CardContent>
            <Alert severity="info" sx={{ mb: 2 }}>
              Read-only feature flags for {domain.urn}.{' '}
              <Link href={`https://ff.hapara.com/customers/${domain.urn}`} target="_blank">
                (Click here to go to production version)
              </Link>
            </Alert>

            {featureFlags.map(item => (
              <Box sx={{ mb: 2 }}>
                <Grid container>
                  {item.Enabled ? (
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      key={(position += 1)}
                      sx={{ mr: 3 }}
                    >
                      ON
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      key={(position += 1)}
                      sx={{ mr: 3 }}
                    >
                      OFF
                    </Button>
                  )}

                  <Link
                    underline="none"
                    href={`https://hapara.atlassian.net/browse/${item.Name.slice(0, 8)}`}
                    target="_blank"
                    key={(position += 1)}
                  >
                    {item.Name.toUpperCase()}
                  </Link>
                </Grid>
              </Box>
            ))}
          </CardContent>
        </Card>
      </Box>
    )
  }
}
