import { useState } from 'react'
import {
  Typography,
  Box,
  FormControl,
  TextField,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
} from '@mui/material'
import {
  UndeleteWorkspaceByUser,
  UndeleteWorkspaceByClass,
  Undelete,
} from '../../../apiCalls/undelete-workspace'

export default function UndeleteWorkspace() {
  const [userData, setUserData] = useState([{}])

  const UndeleteUser = () => {
    const [undeleteByUser, setUndeleteByUser] = useState('')
    const [undeleteByClass, setUndeleteByClass] = useState('')

    const handleUserClick = async () => {
      await UndeleteWorkspaceByUser(undeleteByUser).then(res => {
        const summaryDisplay = res.data.map((items: any) => {
          return {
            WorkspaceID: items?.Id,
            Title: items?.Title,
            Label: items?.Label,
          }
        })
        setUserData(summaryDisplay)
      })
    }

    const handleClassClick = async () => {
      await UndeleteWorkspaceByClass(undeleteByClass).then(res => {
        const summaryDisplay = res.data.map((items: any) => {
          return {
            WorkspaceID: items?.Id,
            Title: items?.Title,
            Label: items?.Label,
          }
        })
        setUserData(summaryDisplay)
      })
    }

    return (
      <Card sx={{ m: 3 }}>
        <CardContent sx={{ m: 3 }}>
          <Box sx={{ pb: 3 }}>
            <Typography variant="h6" sx={{ pb: 2 }}>
              Undelete Workspace By User
            </Typography>
            <Alert sx={{ mb: 2 }} severity="info">
              Fetches all previously deleted workspaces and populates table below (By User).
            </Alert>
            <FormControl fullWidth sx={{ pb: 2 }}>
              <TextField
                placeholder="User"
                value={undeleteByUser}
                onChange={(e: any) => setUndeleteByUser(e.target.value)}
              />
            </FormControl>
            <Button variant="contained" disabled={!undeleteByUser} onClick={handleUserClick}>
              Submit
            </Button>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ pb: 2 }}>
              Undelete Workspace By Class
            </Typography>
            <Alert sx={{ mb: 2 }} severity="info">
              Fetches all previously deleted workspaces and populates table below (By Class).
            </Alert>
            <FormControl fullWidth sx={{ pb: 2 }}>
              <TextField
                placeholder="Class"
                value={undeleteByClass}
                onChange={(e: any) => setUndeleteByClass(e.target.value)}
              />
            </FormControl>
            <Button variant="contained" disabled={!undeleteByClass} onClick={handleClassClick}>
              Submit
            </Button>
          </Box>
        </CardContent>
      </Card>
    )
  }

  const Results = () => {
    const handleUndeleteSubmit = (id: string) => {
      Undelete(id)
        .then(res => {
          alert('Success Undeleting ' + id)
          window.location.reload()
        })
        .catch(err => {
          alert(err)
        })
    }

    let position = 0
    return (
      <Card sx={{ m: 3 }}>
        <CardContent sx={{ m: 3 }}>
          <Alert sx={{ mb: 2 }} severity="info">
            Retrieves deleted workspace and restores it to the users account.
          </Alert>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Label</TableCell>
                  <TableCell>Undelete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData.map((item: any) => (
                  <TableRow
                    key={(position += 1)}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item.WorkspaceID}
                    </TableCell>
                    <TableCell>{item.Title}</TableCell>
                    <TableCell>{item.Label}</TableCell>
                    <TableCell>
                      <Button
                        onClick={(e = item.WorkspaceID) => handleUndeleteSubmit(item.WorkspaceID)}
                      >
                        Undelete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    )
  }

  return (
    <Box>
      <UndeleteUser />
      <Results />
    </Box>
  )
}
