import React, { useEffect } from 'react'
import Panel from '../../components/Panel/Panel'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useSelector } from 'react-redux'
import { getDomain } from '../../state/domain-management/selectors'
import Load from '../../components/Actions/Load'
import { Data } from '../../state/app/types'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import moment from 'moment'
import { getGTXcelRecords, GtxcelStatus, Doc, Event, GTXcelRecord } from '../../apiCalls/gtxcel'
import { CardContent, Dialog, DialogTitle, List, ListItem, ListItemText } from '@mui/material'
import _ from 'lodash'
import { getToken } from '../../auth/handleJWT'

const collapsedTitle = 'Detail'
const panelHeader = 'GTXcel update records'

// TODO verifiy gtxrecordlist or the recorDetails
function areEqual(prevProps: any, nextProps: any) {
  return false
}

function DigitalBackPack() {
  const domain = useSelector(getDomain)
  const [page, setPage] = React.useState(0)
  const [rowPerPage, setRowPerPage] = React.useState(50)
  const [gtxRecords, setGtxRecords] = React.useState<GTXcelRecord[]>([])
  // TODO loading status
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [currentHJWT, setCurrentHJWT] = React.useState<string>('')
  const EVENT_COLUMN_TYPES = {
    TITLE: 'title',
    AUTHOR: 'author',
    COVERURL: 'coverurl',
    DESCRIPTION: 'description',
    LICENSE: 'license',
    PAGES: 'page',
    PATH: 'path',
    PUBLISHERID: 'publisherid',
    TIME: 'time',
    TYPE: 'type',
    USER: 'user',
  }
  const DOC_COLUMN_TYPES = {
    _ID: '_id',
    AUTHOR: 'author',
    COVERURL: 'coverurl',
    CREATED: 'created',
    DESCRIPTION: 'description',
    DOMAIN: 'domain',
    EVENTS: 'events',
    FILENAME: 'filename',
    GSCNAME: 'gscname',
    ISPUBLIC: 'ispublic',
    NUMPAGES: 'numpages',
    PUBLISHERID: 'publisherid',
    PUBSTATUS: 'pubstatus',
    PUBSTATUSMESSAGE: 'pubstatusmessage',
    TITLE: 'title',
    VERSION: 'version',
    VIEWPATH: 'viewpath',
    TAGS: 'tags',
  }
  const eventColumn = _.values(EVENT_COLUMN_TYPES)
  // TODO: sort by column
  useEffect(() => {
    setIsLoading(true)
    const fetchDate = async () => {
      const result: GTXcelRecord[] = await getGTXcelRecords(
        {
          limit: page,
          offset: page * rowPerPage,
        },
        currentHJWT
      )
      setGtxRecords(result)
      setIsLoading(false)
    }
    fetchDate()
  }, [rowPerPage, currentHJWT, page])

  useEffect(() => {
    getToken()
    .then (token => {
      setCurrentHJWT(token.access_token)
    })
  }, [])

  const columns = [
    { id: 'icon', label: '' },
    { id: 'id', label: 'ID', minWidth: 10, format: (value: string) => value.toLocaleString() },
    {
      id: 'title',
      label: 'Title',
      minWidth: 100,
      format: (value: string) => value.toLocaleString(),
    },
    {
      id: 'created',
      label: 'Created',
      minWidth: 100,
      format: (value: string) => value.toLocaleString(),
    },
  ]
  const handlePreviousBtn = () => {
    if (page > 0) {
      setPage(page - 1)
    }
  }

  const handleNextBtn = () => {
    setPage(page + 1)
  }

  const handleSelectChange = (event: SelectChangeEvent) => {
    setRowPerPage(parseInt(event.target.value))
  }

  const RenderTablePagination = (
    <div style={{ marginTop: '10px' }}>
      <Select
        value={rowPerPage.toString()}
        label="row"
        onChange={handleSelectChange}
        style={{ marginRight: '30px' }}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
      <Button
        onClick={handlePreviousBtn}
        style={{ marginRight: '20px' }}
        size="large"
        variant="contained"
      >
        Previous
      </Button>
      <Button onClick={handleNextBtn} size="large" variant="contained">
        Next
      </Button>
    </div>
  )

  const RenderEventsModal = (
    events: Event[],
    eventModalOpen: boolean,
    setEventModalOpen: CallableFunction
  ) => {
    return (
      <Dialog onClose={() => setEventModalOpen(false)} open={eventModalOpen} maxWidth="lg">
        <DialogTitle>Check Events</DialogTitle>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {eventColumn.map(column => (
                  <TableCell align="left" key={column}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((row: Event, index: number) => (
                <TableRow key={index}>
                  {eventColumn.map((column: string, index: number) =>
                    column === EVENT_COLUMN_TYPES.TIME ? (
                      <TableCell key={index}>
                        {moment(row[column as keyof typeof row]).format('DD-MM-YYYY HH:mm:ss')}
                      </TableCell>
                    ) : (
                      <TableCell key={index}>{row[column as keyof typeof row]}</TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>
    )
  }

  const RenderListitemDetail = (key: string, value: any) => {
    return (
      <ListItem key={key}>
        <ListItemText primary={key} secondary={value.toString()} />
      </ListItem>
    )
  }

  const RenderListItemLeft = (doc: Doc) => {
    let result = []
    const [eventModalOpen, setEventModalOpen] = React.useState<boolean>(false)

    for (const [key, value] of Object.entries(doc)) {
      if (key === DOC_COLUMN_TYPES.CREATED) {
        result.push(RenderListitemDetail(key, moment(value).format('DD-MM-YYYY HH:mm:ss')))
        continue
      }
      if (key !== DOC_COLUMN_TYPES.EVENTS && key !== DOC_COLUMN_TYPES.TAGS) {
        result.push(RenderListitemDetail(key, value))
      }
    }
    return (
      <List>
        <ListItem alignItems={'center'}>
          <Button variant="contained" onClick={() => setEventModalOpen(true)}>
            Check Events
          </Button>
        </ListItem>

        {result}
        {eventModalOpen ? RenderEventsModal(doc.events, eventModalOpen, setEventModalOpen) : null}
      </List>
    )
  }
  const RenderListItemRight = (gtxcel: GtxcelStatus) => {
    let result = []
    for (const [key, value] of Object.entries(gtxcel)) {
      if (key === EVENT_COLUMN_TYPES.TIME) {
        result.push(RenderListitemDetail(key, moment(value).format('DD-MM-YYYY HH:mm:ss')))
        continue
      }
      if (key !== DOC_COLUMN_TYPES.EVENTS && key !== DOC_COLUMN_TYPES.TAGS) {
        // events will be processed by a dialog
        result.push(RenderListitemDetail(key, value))
      }
    }
    return <List>{result}</List>
  }

  const RenderListItems = (row: GTXcelRecord) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Card sx={{ width: '45%' }} raised={true}>
          <CardContent>{RenderListItemLeft(row.doc)}</CardContent>
        </Card>
        <Card sx={{ width: '45%' }} raised={true}>
          <CardContent>{RenderListItemRight(row.gtxcelStatus)}</CardContent>
        </Card>
      </div>
    )
  }

  const Row = ({ row }: { row: GTXcelRecord }) => {
    const [open, setOpen] = React.useState<boolean>(false)

    const handleCollapseOpen = () => {
      setOpen(!open)
    }

    return (
      <TableBody key={row.doc._id}>
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={handleCollapseOpen}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.doc._id}
          </TableCell>
          <TableCell align="left">{row.doc.title}</TableCell>
          <TableCell align="left">
            {moment(row.doc.created).format('DD-MM-YYYY HH:mm:ss')}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  {collapsedTitle}
                </Typography>
                <List>{RenderListItems(row)}</List>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </TableBody>
    )
  }
  const RenderTableBody = gtxRecords.map((gtxRecord: GTXcelRecord) => (
    <Row key={gtxRecord.doc._id} row={gtxRecord} />
  ))

  const RenderTableHeader = columns.map(column => (
    <TableCell key={column.id} align="left" style={{ minWidth: column.minWidth }}>
      {column.label}
    </TableCell>
  ))
  // consist of the header and the body
  const RenderTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>{RenderTableHeader}</TableRow>
          </TableHead>
          {RenderTableBody}
        </Table>
      </TableContainer>
    )
  }

  if (isLoading) {
    return <CircularProgress />
  }
  // The framework of this page
  return (
    <Load path={Data.DomainDigitalBackpackCurators} params={{ domain: domain.URN }}>
      <Panel header={panelHeader}>
        {RenderTable()}
        {RenderTablePagination}
      </Panel>
    </Load>
  )
}

export default React.memo(DigitalBackPack, areEqual)
