import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
// TODO AUTH
const configSetup = (
  config: AxiosRequestConfig
): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  return config
}

const responseSuccessInterceptors = (resp: AxiosResponse<any>) => {
    return resp.data
}
const responseFailInterceptors = (error: AxiosResponse<any>) => {
  return Promise.reject(error)
}

const axiosWithInterceptors: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HAPARA_URL}`,
})
const axiosWithoutInterceptors: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HAPARA_URL}`,
})

axiosWithInterceptors.interceptors.request.use(configSetup)
axiosWithInterceptors.interceptors.response.use(responseSuccessInterceptors,responseFailInterceptors)

export { axiosWithInterceptors, axiosWithoutInterceptors }
