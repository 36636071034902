import { useState } from 'react'
import { getDomain } from '../../../../state/domain-management/selectors'
import { useSelector } from 'react-redux'
import {
  Card,
  CardContent,
  Typography,
  Link,
  FormControl,
  TextField,
  Box,
  Button,
} from '@mui/material'

export default function GeneralLoad() {
  const domain = useSelector(getDomain)
  const [singleProcessDetails, setSingleProcessDetails] = useState('')
  const [processListLoad, setProcessListLoad] = useState('')
  const [retryCrashedProcess, setRetryCrashedProcess] = useState('')

  return (
    <Card sx={{ m: 3 }}>
      <CardContent>
        <Typography variant="h2" align="center">
          General Load
        </Typography>
        <Card sx={{ m: 3 }}>
          <CardContent>
            <Link
              href={'https://hugs-dl.teacherdashboard.com/admin/#/main/group/urn/' + domain.urn}
              target="_blank"
            >
              HUGS
            </Link>
          </CardContent>
        </Card>
        <Card sx={{ m: 3 }}>
          <CardContent>
            <Box sx={{ pb: 3 }}>
              <FormControl fullWidth>
                <Typography variant="h6">Single Process Details</Typography>
                <TextField
                  sx={{ pb: 3 }}
                  value={singleProcessDetails}
                  onChange={(e: any) => setSingleProcessDetails(e.target.value)}
                  placeholder="Enter Process ID"
                />
                <Button
                  variant="contained"
                  component={Link}
                  disabled={singleProcessDetails.length < 1}
                  href={
                    'https://mbr-dot-h-script-x.appspot.com/util/procview?pid=' +
                    singleProcessDetails
                  }
                  target="_blank"
                >
                  Submit
                </Button>
              </FormControl>
            </Box>

            <Box sx={{ pb: 3 }}>
              <FormControl fullWidth>
                <Typography variant="h6">Process List For Load</Typography>
                <TextField
                  sx={{ pb: 3 }}
                  value={processListLoad}
                  onChange={(e: any) => setProcessListLoad(e.target.value)}
                  placeholder="Enter Load ID"
                />
                <Button
                  variant="contained"
                  component={Link}
                  disabled={processListLoad.length < 1}
                  href={
                    'https://mbr-dot-h-script-x.appspot.com/util/proclist?load_id=' +
                    processListLoad +
                    '&domain=' +
                    domain.urn +
                    '&state=50'
                  }
                  target="_blank"
                >
                  Submit
                </Button>
              </FormControl>
            </Box>

            <Box>
              <FormControl fullWidth>
                <Typography variant="h6">Retry Crashed Process</Typography>
                <TextField
                  sx={{ pb: 3 }}
                  value={retryCrashedProcess}
                  onChange={(e: any) => setRetryCrashedProcess(e.target.value)}
                  placeholder="Enter Load ID"
                />
                <Button
                  variant="contained"
                  component={Link}
                  disabled={retryCrashedProcess.length < 1}
                  href={
                    'https://mbr-dot-h-script-x.appspot.com/util/procretry?load_id=' +
                    retryCrashedProcess +
                    '&domain=' +
                    domain.urn
                  }
                  target="_blank"
                >
                  Submit
                </Button>
              </FormControl>
            </Box>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  )
}
