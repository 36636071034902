import 'whatwg-fetch'

import { getToken } from '../auth/handleJWT'

export async function getDomainInfo({ domain }) {
  const { access_token } = await getToken()
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/admin/domain?domain=${domain}`
  const opts = {
    headers: { Authorization: access_token },
  }
  const response = await fetch(url, opts)

  if (!response.ok) {
    throw response
  }
  return await response.json()
}

const mock = [
  { email: 'one@mar.zawadzki.co.nz', name: 'Name 1' },
  { email: 'two@mar.zawadzki.co.nz', name: 'Name 2' },
  { email: 'three@mar.zawadzki.co.nz', name: 'Name 3' },
]
export async function getCurators({ domain }) {
  return mock
}

export async function addCurator({ domain, email }) {
  mock.push({ email, name: 'New user' + mock.length })
  return 'OK'
}
