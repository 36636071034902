import axios from 'axios'
import { getToken } from '../auth/handleJWT' 
// this instance's endpoint is the hapara go-microservice
import { axiosWithInterceptors } from './axios'

const temporalAxios = axios.create({
  baseURL: process.env.REACT_APP_TEMPORAL_URL,
})
interface Execution {
  runId: string
  workflowId: string
}
interface TaskHistory {
  execution: Execution
  type: { name: string }
  startTime: string
  closeTime: string
  status: string
  historyLength: string
  parentNamespaceId: string
  parentExecution: any
  executionTime: string
  memo: any
  searchAttributes: { BinaryChecksums: string }
  autoResetPoints: any
  taskQueue: string
  stateTransitionCount: string
}

interface CollectionItem {
  id: string
  name: string
}

const queryTaskHistoryClosed = async (startTime: Date, endTime: Date, nextPageToken?: string) => {
  return await temporalAxios.get(`/api/namespaces/hapara-ns/workflows/closed`, {
    params: {
      startTime,
      endTime,
      nextPageToken,
    },
  })
}

const queryTaskHistoryOpen = async (startTime: Date, endTime: Date, nextPageToken?: string) => {
  return await temporalAxios.get(`/api/namespaces/hapara-ns/workflows/open`, {
    params: {
      startTime,
      endTime,
      nextPageToken,
    },
  })
}

const queryTaskDetail = async (workflowId: string, runId: string) => {
  return await temporalAxios.get(`/api/namespaces/hapara-ns/workflows/${workflowId}/${runId}`)
}

const queryTaskDetailHistory = async (workflowId: string, runId: string) => {
  return await temporalAxios.get(
    `/api/namespaces/hapara-ns/workflows/${workflowId}/${runId}/history?waitForNewEvent=true`
  )
}

const queryCollectionsNameByID = async (IDs: string[]) => {
  const { access_token } = await getToken()
  return await axiosWithInterceptors.post<string, CollectionItem[]>(
    '/library/hap-admin/getCollectionsName',
    {
      IDs: IDs,
    },
    {
      headers: { Authorization: access_token },
    }
  )
}

const refreshCatalog = async () => {
  const { access_token } = await getToken()
  return await axiosWithInterceptors.post(
    '/library/hap-admin/updateMasterCatalog',
    {},
    {
      headers: { Authorization: access_token },
    }
  )
}

export {
  queryTaskHistoryClosed,
  queryTaskHistoryOpen,
  queryTaskDetail,
  queryTaskDetailHistory,
  queryCollectionsNameByID,
  refreshCatalog,
}
export type { TaskHistory }
