import React, { useState } from 'react'
import { Backdrop, Button, CircularProgress, Dialog, DialogActions } from '@material-ui/core'
import { doAction } from '../../state/app/actions'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import { Actions } from '../../state/app/types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import json2mq from 'json2mq'
import { useAppDispatch, AppDispatch } from '../../state/store'

const DialogContent: React.FunctionComponent<{
  handleClose: () => void
  action: Actions
  getFormData: () => object
  isError: boolean
  setIsError: (a: boolean) => void
  setInProgress: (a: boolean) => void
}> = ({ handleClose, action, getFormData, children, isError, setIsError, setInProgress }) => {
  const dispatch: AppDispatch = useAppDispatch()
  return (
    <React.Fragment>
      {isError && <Alert>Something went wrong, try again later.</Alert>}
      {children}
      <DialogActions>
        <Button
          onClick={() => {
            handleClose()
            setIsError(false)
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            const params = getFormData()
            setInProgress(true)
            const act = doAction(action, params)
            dispatch(act)
              .then(() => {
                handleClose()
              })
              .catch((e: any) => {
                console.log(e)
                setIsError(true)
              })
              .finally(() => {
                setInProgress(false)
              })
          }}
        >
          OK
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 100,
  },
  content: {
    minWidth: 600,
  },
}))

interface Props {
  isOpened: boolean
  handleClose: () => void
  action: Actions
  getFormData: () => object
}

const ActionDialog: React.FunctionComponent<Props> = ({
  isOpened,
  handleClose,
  action,
  getFormData,
  children,
}) => {
  const [inProgress, setInProgress] = useState(false)
  const [isError, setIsError] = useState(false)
  const classes = useStyles()
  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: 600,
    })
  )

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      aria-labelledby="action-form-dialog-title"
      fullScreen={isMobile}
      maxWidth={'lg'}
    >
      <DialogContent
        handleClose={handleClose}
        action={action}
        getFormData={getFormData}
        setInProgress={setInProgress}
        setIsError={setIsError}
        isError={isError}
      >
        <div className={classes.content}>{children}</div>
        <Backdrop open={inProgress} className={classes.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  )
}

export default ActionDialog
