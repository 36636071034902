import { useEffect, useState, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { isLoadedJwt, isLoadingJwt, IsFailedToLoadJwt } from '../../state/user/selectors'
import { LOGIN_TEXT } from './content'
import { initHaparaJWT } from '../../auth/handleJWT'
import LinearProgress from '@mui/material/LinearProgress'
import Alert from '@mui/material/Alert'
import classnames from 'classnames'
import logo from 'hapara-assets/src/images/logo-hapara.svg'
import useStyles from './styles'

interface GoogleLoginProps {
  renderSignOut: boolean
}

const GoogleLogin = ({ renderSignOut }: GoogleLoginProps) => {
  const [scriptInitialised, setScriptInitialised] = useState<boolean>(false) //Ensures Google Client is loaded

  const hjwtLoading = useSelector(isLoadingJwt)
  const hjwtLoaded = useSelector(isLoadedJwt)
  const hjwtError = useSelector(IsFailedToLoadJwt)

  const loginButtonRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()

  const signInCallback = useCallback(res => {
    if (res.clientId || res.credential) {
      initHaparaJWT(res.credential)
    }
  }, [])

  const renderSignInButton = () => {
    window.google?.accounts.id.renderButton(loginButtonRef.current!, {
      type: 'standard',
      theme: 'outline',
      size: 'large',
      shape: 'rectangular',
      logo_alignment: 'center',
    })
  }

  useEffect(() => {
    //1: LOAD SCRIPT AND CALL INITIALISE
    if (!window.google && !scriptInitialised) {
      const script = document.createElement('script')
      script.src = 'https://accounts.google.com/gsi/client'
      script.onload = () => {
        intialiseGoogleClient()
      }
      script.async = true
      script.defer = true
      script.id = 'google-client-script'
      document.querySelector('body')?.appendChild(script)
    }

    //2: INITIALISE GOOGLE CLIENT
    const intialiseGoogleClient = () => {
      if (window.google && !scriptInitialised) {
        window.google.accounts.id.initialize({
          client_id: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
          callback: signInCallback,
          auto_select: false,
        })
        setScriptInitialised(true)
      }
    }

    //3: RENDER BUTTON AND PROMPT
    if (!renderSignOut) {
      renderSignInButton()
    }

    //4: CLEAN UP ON UNMOUNT
    return () => {
      window.google?.accounts.id.cancel()
      document.getElementById('google-client-script')?.remove()
    }
  }, [renderSignOut, hjwtLoaded, scriptInitialised, signInCallback])

  return (
    <>
      {renderSignOut === false && (
        <div className={classes.loginContainer}>
          <div className={classnames(classes.logoWrapper)}>
            <img src={logo} alt={LOGIN_TEXT.LOGO_ALT} />
            <h2>{LOGIN_TEXT.ADMIN}</h2>
          </div>
          <div className={classes.buttonWrapper}>
            {!hjwtLoading && <div ref={loginButtonRef}></div>}
            {hjwtLoading && <LinearProgress className={classes.loadingBar} color="inherit" />}
          </div>
        </div>
      )}
      {hjwtError && <Alert severity="error">{LOGIN_TEXT.ERROR}</Alert>}
    </>
  )
}

export default GoogleLogin
