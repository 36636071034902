import React from 'react'
import { AppBar, Button, Dialog, Divider, IconButton, Toolbar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import json2mq from 'json2mq'
import PropTypes from 'prop-types'

const MAX_DESKTOP_DETAILS_WIDTH = 900

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    padding: 2 * theme.spacing(),
  },
  grow: {
    flexGrow: 1,
  },
  content: {
    minWidth: MAX_DESKTOP_DETAILS_WIDTH,
    overflow: 'scroll',
  },
}))

export default function Details({ isOpened, handleClose, children, header }) {
  const classes = useStyles()
  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: MAX_DESKTOP_DETAILS_WIDTH,
    })
  )
  if (isMobile) {
    return (
      <Dialog
        open={isOpened}
        onClose={handleClose}
        aria-labelledby="details-dialog-title"
        fullScreen
      >
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h5" component="h1">
              {header}
            </Typography>
            <div className={classes.grow}></div>
            <IconButton
              edge="end"
              className={classes.menuButton}
              color="inherit"
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Toolbar></Toolbar>
        {children}
      </Dialog>
    )
  }
  return (
    <Dialog
      open={isOpened || false}
      onClose={handleClose}
      aria-labelledby="details-dialog-title"
      maxWidth={'lg'}
    >
      <div className={classes.title}>
        <Typography variant="h5" component="h1">
          {header}
        </Typography>
        <div className={classes.grow}></div>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </div>
      <Divider />
      <div className={classes.content}>{children}</div>
    </Dialog>
  )
}

Details.propTypes = {
  isOpened: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
}
Details.defaultProps = {
  isOpened: false,
}
