import PropTypes from 'prop-types'

const getElements = () => {
  if (process.env.REACT_APP_BUILD_ENV === 'test') {
    return ['hydrogen', 'helium']
  }
  return ['hydrogen', 'helium', 'lithium', 'beryllium', 'boron', 'carbon', 'nitrogen', 'oxygen', 'lutetium', 'nyc']
}

export const ELEMENTS = getElements()

export const BUS_TYPE = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  domains_count: PropTypes.number.isRequired,
})
