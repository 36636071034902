import { getDomain } from '../../../../state/domain-management/selectors'
import { useSelector } from 'react-redux'
import { Card, CardContent, Typography, Link, Grid } from '@mui/material'

export default function Links() {
  const domain = useSelector(getDomain)

  return (
    <Card sx={{ m: 3 }}>
      <Typography variant="h2" align="center">
        Links
      </Typography>
      <CardContent>
        <Card sx={{ m: 3 }}>
          <CardContent>
            <Grid container direction="column" justifyContent="center" alignItems="flex-start">
              <Link href="https://td-admin.appspot.com/util/riconedistricts" target="_blank">
                RICONE Districts
              </Link>
              <Link
                href={'https://mbr-dot-h-script-x.appspot.com/util/gcdinfo?domain=' + domain.urn}
                target="_blank"
              >
                Monitor or Take Control of a Queue
              </Link>
            </Grid>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  )
}
