import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Panel from '../../components/Panel/Panel'
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getDomain } from '../../state/domain-management/selectors'
import moment from 'moment-timezone'
import ActionDialog from '../../components/Actions/ActionDialog'
import { Actions } from '../../state/app/types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ELEMENTS } from '../../state/internal-admin/types'

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
  },
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 900,
  },
  table: {
    minWidth: 650,
  },
}))

export default function HighlightsTroubleshooting() {
  const classes = useStyles()
  const domain = useSelector(getDomain)
  const [editOpened, setEditOpened] = useState(false)
  const [moveFormValues, setMoveFormValues] = useState({
    destination: 'hydrogen',
    domain: domain.URN,
  })
  const aucklandZone = moment.tz.zone(domain.Timezone || 'Pacific/Auckland')
  let nzOffset = 0
  if (aucklandZone != null) {
    nzOffset =
      aucklandZone.utcOffset(new Date().getTime()) / 60 -
      aucklandZone.utcOffset(new Date().getTime()) / 60
  }
  const startPeak = moment()
    .startOf('day')
    .add(8 + nzOffset, 'hours')
  const endPeak = moment()
    .startOf('day')
    .add(8 + nzOffset, 'hours')
    .add(8, 'hours')
  return (
    <div className={classes.root}>
      <Panel header="Highlights Configuration">
        <TableContainer>
          <Table className={classes.table} aria-label="configiration table">
            <TableBody>
              <TableRow>
                <TableCell>URN</TableCell>
                <TableCell>
                  <Typography>{'' + domain.URN}</Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Active</TableCell>
                <TableCell>
                  <Typography>{'' + domain.Active}</Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>HL Bus</TableCell>
                <TableCell>
                  <Typography>{'' + domain.HLBus}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setEditOpened(true)
                    }}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Timezone</TableCell>
                <TableCell>
                  <Typography>{'' + domain.Timezone}</Typography>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Current Time</TableCell>
                <TableCell>
                  <Typography>
                    {moment()
                      .tz(domain.Timezone || 'Pacific/Auckland')
                      .format('ddd MMM Do hA')}
                  </Typography>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Offset from NZ</TableCell>
                <TableCell>
                  <Typography>{nzOffset}</Typography>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Peak usage in NZ time</TableCell>
                <TableCell>
                  <Typography>
                    {startPeak.format('h:mm a')} - {endPeak.format('h:mm a')}
                  </Typography>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Panel>
      <ActionDialog
        isOpened={editOpened}
        handleClose={() => setEditOpened(false)}
        action={Actions.MoveOneDomain}
        getFormData={() => {
          return moveFormValues
        }}
      >
        <DialogTitle id="move-domains-form-dialog-title">Move domain</DialogTitle>
        <DialogContent>
          <DialogContentText>Move domain to a different bus</DialogContentText>
          <Autocomplete
            id="destination"
            options={ELEMENTS}
            getOptionLabel={option => option}
            defaultValue={moveFormValues.destination}
            onChange={(e, v) => {
              setMoveFormValues({ ...moveFormValues, destination: v || '' })
            }}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                margin="dense"
                label="Select destination bus"
                variant="outlined"
              />
            )}
          />
        </DialogContent>
      </ActionDialog>
    </div>
  )
}
