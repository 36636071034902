import { useEffect } from 'react'
import { getToken } from '../../auth/handleJWT'

const LoginHandler = () => {
  useEffect(() => {
    const sessionDetails = localStorage.getItem('session_details') || '{}'
    const { user_signedIn } = JSON.parse(sessionDetails)

    if (user_signedIn) {
      getToken()
    }
  }, [])

  return null
}

export default LoginHandler
