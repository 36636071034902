import React from 'react'
import SideBar from '../../components/Authentication/Layout/SideBar'
import { useParams } from 'react-router-dom'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import ListIcon from '@material-ui/icons/List'
import RowingIcon from '@material-ui/icons/Rowing'
import SchoolIcon from '@material-ui/icons/School'
import { NavigationProps, NavigationParams } from '../types'
import { SideBarItem, SideBarTypes } from '../../state/app/types'

const items: SideBarItem[] = [
  {
    title: 'User Summary',
    path: '/summary',
    id: 'summary',
    icon: <SchoolIcon />,
    type: SideBarTypes.MENU_ITEM,
  },
  {
    title: 'Internal',
    type: SideBarTypes.DIVIDER,
    id: 'internal-divider',
    icon: null,
    path: '',
  },
  {
    title: 'Tasks',
    path: '/tasks',
    id: 'tasks',
    type: SideBarTypes.MENU_ITEM,
    icon: <ListIcon />,
  },
  {
    title: 'Activity',
    path: '/activity',
    type: SideBarTypes.MENU_ITEM,
    id: 'activity',
    icon: <RowingIcon />,
  },
  {
    title: 'Highlights Logs',
    path: '/hl-logs',
    id: 'hl-logs',
    type: SideBarTypes.MENU_ITEM,
    icon: <ReportProblemIcon />,
  },
  {
    title: 'Old Highlights Logs',
    path: '/old-logs',
    id: 'old-logs',
    type: SideBarTypes.MENU_ITEM,
    icon: <ReportProblemIcon />,
  },
]

export default function UserManagementNavigation({ path, query }: NavigationProps) {
  const params = useParams<NavigationParams>()
  return (
    <SideBar selected={params.id} items={items.map(i => ({ ...i, path: path + i.path + query }))} />
  )
}
