import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Panel from '../../components/Panel/Panel'
import Load from '../../components/Actions/Load'
import TimePicker, { useTimeRange, DEFAULT_TIMEZONE } from '../../components/Actions/TimePicker'
import { Data } from '../../state/app/types'
import { useSelector } from 'react-redux'
import { getSelectedUser, getUserTasks } from '../../state/user-management/selectors'
import { getDomain } from '../../state/domain-management/selectors'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Modal from '@material-ui/core/Modal'
import ReactJson from 'react-json-view'
import _ from 'lodash'

import { Button, Paper } from '@material-ui/core'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  chipsContainer: {
    padding: theme.spacing(8),
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(8),
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 600,
    maxHeight: 600,
    overflow: 'scroll',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
  configuration: {
    display: 'flex',
  },
  slider: {
    maxWidth: 200,
  },
  table: {
    minWidth: 650,
  },
}))

export default function UserTasks() {
  const classes = useStyles()
  const user = useSelector(getSelectedUser) as any
  const domain = useSelector(getDomain)
  const tasks = useSelector(getUserTasks)
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [infoModalContent, setInfoModalContent] = useState({})
  const [timezone, setTimezone] = useState(DEFAULT_TIMEZONE)
  const [beforeTimeRange, setBeforeTimeRange] = useState(20160) //2 weeks by default
  const [timeRangeFrom, timeRangeTo] = useTimeRange(beforeTimeRange)

  return (
    <div className={classes.root}>
      <h2>User Tasks</h2>
      <Modal className={classes.modal} open={infoModalOpen} onClose={() => setInfoModalOpen(false)}>
        <div className={classes.paper}>
          <ReactJson src={infoModalContent} />
        </div>
      </Modal>
      <Panel
        header={`User Info: ${
          _.get(user, 'roles').indexOf('student') < 0 ? '' : 'STUDENT  '
        } ${_.get(user, 'primary_email')}`}
      >
        <ReactJson src={user} collapsed={true} style={{ width: '100%' }} />
        <a
          rel="noreferrer"
          href={`https://hugs.teacherdashboard.com/admin/_/#/main/user/urn/${_.get(
            user,
            'primary_email'
          )}/raw`}
          target="_blank"
        >
          Hugs user
        </a>
      </Panel>
      <Panel header={`Domain Info: ${_.get(domain, 'URN')}`}>
        <ReactJson src={domain} collapsed={true} style={{ width: '100%' }} />
        <a
          rel="noreferrer"
          href={`https://hugs.teacherdashboard.com/admin/_/#/main/group/urn/${_.get(
            domain,
            'URN'
          )}/raw`}
          target="_blank"
        >
          Hugs domain
        </a>
      </Panel>
      <TimePicker timezone={timezone} onTimezoneChange={setTimezone} onBeforeRangeChange={setBeforeTimeRange}/>
      <Load
        path={Data.UserTasks}
        params={{
          user: _.get(user, 'primary_email'),
          from: timeRangeFrom,
          to: timeRangeTo,
        }}
      >
        <Panel header="Tasks">
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableBody>
                {(tasks || []).map((row: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="left">
                        {moment(row.Sent).tz(timezone).format('YYYY-MM-DD HH:mm:ss')}
                      </TableCell>
                      <TableCell align="left">
                        <Link href={`./task/${row.ID}`}>{row.task.Name}</Link>
                      </TableCell>
                      <TableCell align="left">{row.Status}</TableCell>
                      <TableCell align="right">
                        {
                          <Button
                            onClick={() => {
                              setInfoModalContent(row)
                              setInfoModalOpen(true)
                            }}
                          >
                            All Details
                          </Button>
                        }{' '}
                        {
                          <Button
                            onClick={() => {
                              setInfoModalContent(row.Payload)
                              setInfoModalOpen(true)
                            }}
                          >
                            Payload
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Panel>
      </Load>
    </div>
  )
}
