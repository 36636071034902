const actionTypes = {
  APP_USER_JWT_SUCCESS: 'APP_USER_JWT_SUCCESS',
  APP_USER_JWT_ERROR: 'APP_USER_JWT_ERROR',
  APP_USER_JWT_PENDING: 'APP_USER_JWT_PENDING',

  APP_HJWT_PENDING: 'APP_HJWT_PENDING',
  APP_HJWT_SUCCESS: 'APP_HJWT_SUCCESS',
  APP_HJWT_ERROR: 'APP_HJWT_ERROR',
  APP_HJWT_SIGNOUT: 'APP_HJWT_SIGNOUT',

  APP_INFO_PENDING: 'APP_INFO_PENDING',
  APP_INFO_SUCCESS: 'APP_INFO_SUCCESS',
  APP_INFO_ERROR: 'APP_INFO_ERROR',
  APP_INFO_CLEAR: 'APP_INFO_CLEAR',

  UI_OPEN_SIDE_BAR: 'UI_OPEN_SIDE_BAR',
  UI_CLOSE_SIDE_BAR: 'UI_CLOSE_SIDE_BAR',
}

export default actionTypes
