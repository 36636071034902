import { axiosWithInterceptors } from './axios'
import { getToken } from '../auth/handleJWT'

interface getDRMgroupInput {
  domain: string
  groupEmail: string
}

export const getDRMgroup = async (domain: string, groupEmail: string) => {
  const { access_token } = await getToken()
  return await axiosWithInterceptors.get<getDRMgroupInput, string[]>(
    `/admin/admin/lite/listgroup/${domain}/${groupEmail}`,
    {
      headers: { Authorization: access_token },
    }
  )
}
