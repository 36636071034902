import React from 'react'
import useStyles from './styles'
import { logOut } from '../../auth/handleJWT'
import { LOGIN_TEXT } from './content'

const SignOut = () => {
  const classes = useStyles()
  const sessionDetails = JSON.parse(localStorage.getItem('session_details') || '{}')
  const { user_signedIn } = sessionDetails

  const handleSignOut = () => {
    window.google?.accounts.id.disableAutoSelect()
    logOut()
  }

  return (
    <>
      {user_signedIn && (
        <div className={classes.signOutButton} onClick={handleSignOut}>
          {LOGIN_TEXT.SIGN_OUT}
        </div>
      )}
    </>
  )
}

export default SignOut
