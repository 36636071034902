import {
  UserDetailsRequest,
  UserDetailsResponse,
  LoadFunc,
  ExtensionLogsResponse,
  ExtensionLogsRequest,
  ExtensionStateChangesRequest,
  ExtensionStateChangesResponse,
  StudentClassesRequest,
  StudentClassesResponse,
  UserTasksRequest,
  UserTasksResponse,
  TaskGroupRequest,
  TaskGroupResponse,
} from './types'
import axios from 'axios'
import { getToken } from '../auth/handleJWT'

export const getUserInfo: LoadFunc<UserDetailsRequest, UserDetailsResponse> = async (
  params
) => {
  const { access_token } = await getToken()
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/admin/user?id=${params.id}`
  const opts = {
    headers: { Authorization: access_token },
  }
  const response = await fetch(url, opts)
  if (!response.ok) {
    throw response
  }
  return await response.json()
}

export const getStudentClasses: LoadFunc<StudentClassesRequest, StudentClassesResponse> = async (
  params
) => {
  const { access_token } = await getToken()
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/admin/student-classes?id=${params.userID}`
  const opts = {
    headers: { Authorization: access_token },
  }
  const response = await fetch(url, opts)
  if (!response.ok) {
    throw response
  }
  return await response.json()
}

export const getExtensionLogs: LoadFunc<ExtensionLogsRequest, ExtensionLogsResponse> = async (
  params
) => {
  const { access_token } = await getToken()
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/admin/extension-logs?student=${params.student}&from=${params.from}&to=${params.to}`
  const opts = {
    headers: { Authorization: access_token },
  }
  const response = await fetch(url, opts)
  if (!response.ok) {
    throw response
  }
  return await response.json()
}

export const getSessionStateChangesLogs: LoadFunc<
  ExtensionStateChangesRequest,
  ExtensionStateChangesResponse
> = async params => {
  const { access_token } = await getToken()
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/hlstate/admin/session-state-changes`
  const opts = {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json, text/plain, */*',
    headers: { Authorization: access_token },
  }
  const js = await axios.post(
    url,
    { ClassURNs: params.classURNs, From: params.from, To: params.to },
    opts
  )
  return js.data
}

export const getOldExtensionLogs: LoadFunc<ExtensionLogsRequest, ExtensionLogsResponse> = async (
  params
) => {
  const { access_token } = await getToken()
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/admin/admin/old-logs?student=${params.student}&from=${params.from}&to=${params.to}`
  const opts = {
    headers: { Authorization: access_token },
  }
  const response = await fetch(url, opts)
  if (!response.ok) {
    throw response
  }
  return await response.json()
}

export const getUserTasks: LoadFunc<UserTasksRequest, UserTasksResponse> = async (
  params
) => {
  const { access_token } = await getToken()
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/tasks/admin/query`
  const opts = {
    headers: { Authorization: access_token },
  }
  const js = await axios.post(url, { user: params.user, from: params.from, to: params.to }, opts)
  return js.data
}

export const getTaskGroup: LoadFunc<TaskGroupRequest, TaskGroupResponse> = async (
  params
) => {
  const { access_token } = await getToken()
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/tasks/admin/group`
  const opts = {
    headers: { Authorization: access_token },
  }
  const js = await axios.post(url, { id: params.id }, opts)
  return js.data
}
