import React, { useState, useEffect } from 'react'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Link,
  CircularProgress,
} from '@mui/material'
import { getDomainData, getPugsData } from '../../../apiCalls/hugs-teacher-dash'
import { useSelector } from 'react-redux'
import { getDomain } from '../../../state/domain-management/selectors'
import { getToken } from '../../../auth/handleJWT'

export default function ReportData() {
  const domain = useSelector(getDomain)
  const [domainData, setDomainData] = useState([{}])
  const [isLoading, setIsLoading] = useState(true)
  const [pugsData, setPugsData] = useState({})
  const [isPugsDataSuccess, setIsPugsDataSuccess] = useState(true)
  const [currentHJWT, setCurrentHJWT] = useState('')

  useEffect(() => {
    getToken()
    .then (token => {
      setCurrentHJWT(token.access_token)
    })
  }, [])

  useEffect(() => {
    getDomainData(domain.URN)
      .then(res => {
        setDomainData(previousData => (previousData = res.data))
      })
      .catch(err => {
        alert(
          'There has been an issue loading data. Please check your domain, refresh and try again.'
        )
      })

    getPugsData(domain.URN)
      .then(res => {
        setPugsData(previousData => (previousData = res.data))
        setIsLoading(false)
      })
      .catch(setIsPugsDataSuccess(false))
  }, [domain, currentHJWT])

  if (!isLoading) {
    return (
      <Box sx={{ width: 1 }}>
        <Card sx={{ m: 3 }}>
          <CardContent sx={{ m: 3 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Pref Name</b>
                    </TableCell>
                    <TableCell>
                      <b>Pref Value</b>
                    </TableCell>
                    <TableCell>
                      <b>Definition</b>
                    </TableCell>
                    <TableCell>
                      <b>Value-add</b>
                    </TableCell>
                    <TableCell>
                      <b>Link</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>domain</TableCell>
                    <TableCell>
                      {!!domainData.URN ? (
                        <Box sx={{ color: 'success.main' }}>{domainData.URN}</Box>
                      ) : (
                        <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>isActive</TableCell>
                    <TableCell>
                      {!!domainData.Active ? (
                        <Box sx={{ color: 'success.main' }}>{domainData.Active?.toString()}</Box>
                      ) : (
                        <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>primary_contact</TableCell>
                    <TableCell>
                      {!!domainData.AdminUser ? (
                        <Box sx={{ color: 'success.main' }}>{domainData.AdminUser}</Box>
                      ) : (
                        <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                      )}
                    </TableCell>
                    <TableCell>Primary contacted listed in HAC</TableCell>
                    <TableCell>Link to HAC Support</TableCell>
                    <TableCell>
                      <Link
                        href={`https://td-admin.appspot.com/-${domain.URN}?page=support`}
                        target="_blank"
                      >
                        Link
                      </Link>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <b>Data Loading</b>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>load_year_suffix</TableCell>
                    <TableCell>
                      {!!domainData.dataload_config?.year ? (
                        <Box sx={{ color: 'success.main' }}>{domainData.dataload_config?.year}</Box>
                      ) : (
                        <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>create_gc_class_enabled</TableCell>
                    <TableCell>
                      {!!domainData.class_management_config?.google_classroom_sync_enabled.toString() ? (
                        <Box sx={{ color: 'success.main' }}>
                          {domainData.class_management_config?.google_classroom_sync_enabled?.toString()}
                        </Box>
                      ) : (
                        <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                      )}
                    </TableCell>
                    <TableCell>Allow teachers to sync Google Classroom classes</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <Link
                        href={`https://settings.teacherdashboard.com/#/d/-${domain.URN}/config/optional/classes`}
                        target="_blank"
                      >
                        Link
                      </Link>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>create_class_enabled</TableCell>
                    <TableCell>
                      {!!domainData.class_management_config?.add_class_enabled?.toString() ? (
                        <Box sx={{ color: 'success.main' }}>
                          {domainData.class_management_config?.add_class_enabled?.toString()}
                        </Box>
                      ) : (
                        <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                      )}
                    </TableCell>
                    <TableCell>Allow teachers to manually create new classes</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <Link
                        href={`https://settings.teacherdashboard.com/#/d/-${domain.URN}/config/optional/classes`}
                        target="_blank"
                      >
                        Link
                      </Link>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>teachers_add_teachers_enabled</TableCell>
                    <TableCell>
                      <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                    </TableCell>
                    <TableCell>Allow teachers to add teachers to existing classes</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <Link
                        href={`https://settings.teacherdashboard.com/#/d/-${domain.URN}/config/optional/classes`}
                        target="_blank"
                      >
                        Link
                      </Link>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>provision_students</TableCell>
                    <TableCell>
                      <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                    </TableCell>
                    <TableCell>Allow teachers to add students to existing classes</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <Link
                        href={`https://settings.teacherdashboard.com/#/d/-${domain.URN}/config/optional/classes`}
                        target="_blank"
                      >
                        Link
                      </Link>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>sis_enabled</TableCell>
                    <TableCell>
                      <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                    </TableCell>
                    <TableCell>Type of SIS sync (if enabled)</TableCell>
                    <TableCell>link to HAC Sync</TableCell>
                    <TableCell>
                      <Link
                        href={`"https://settings.teacherdashboard.com/#/d/-${domain.URN}/loader/sis-integration"`}
                        target="_blank"
                      >
                        Link
                      </Link>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>sis_student_sync</TableCell>
                    <TableCell>
                      <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                    </TableCell>
                    <TableCell>Student data configured to sync</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>sis_class_sync</TableCell>
                    <TableCell>
                      <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                    </TableCell>
                    <TableCell>Class data configured to sync</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <b>Hāpara Core Features</b>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>dashboard_enabled</TableCell>
                    <TableCell>
                      {!!domainData.features?.dashboard.toString() ? (
                        <Box sx={{ color: 'success.main' }}>
                          {domainData.features?.dashboard.toString()}
                        </Box>
                      ) : (
                        <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>link to HAC Optional Settings</TableCell>
                    <TableCell>
                      <Link
                        href={`https://settings.teacherdashboard.com/#/d/-${domain.URN}/config/optional`}
                        target="_blank"
                      >
                        Link
                      </Link>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>workspace_enabled</TableCell>
                    <TableCell>
                      {!!domainData.features?.workspace?.toString() ? (
                        <Box sx={{ color: 'success.main' }}>
                          {domainData.features?.workspace?.toString()}
                        </Box>
                      ) : (
                        <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>link to HAC Workspace</TableCell>
                    <TableCell>
                      <Link
                        href={`https://td-admin.appspot.com/-${domain.URN}?page=workspace`}
                        target="_blank"
                      >
                        Link
                      </Link>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>highlights_enabled</TableCell>
                    <TableCell>
                      {!!domainData.features?.highlights?.toString() ? (
                        <Box sx={{ color: 'success.main' }}>
                          {domainData.features?.highlights?.toString()}
                        </Box>
                      ) : (
                        <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>link to HAC highlights</TableCell>
                    <TableCell>
                      <Link
                        href={`https://td-admin.appspot.com/-${domain.URN}?page=highlights`}
                        target="_blank"
                      >
                        Link
                      </Link>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>student_dashboard_enabled</TableCell>
                    <TableCell>
                      {!!domainData.features?.studentdashboard?.toString() ? (
                        <Box sx={{ color: 'success.main' }}>
                          {domainData.features?.studentdashboard?.toString()}
                        </Box>
                      ) : (
                        <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <b>Other Features</b>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>workspace_private_library_enabled</TableCell>
                    <TableCell>
                      {!!domainData.workspace_config?.private_library_enabled?.toString() ? (
                        <Box sx={{ color: 'success.main' }}>
                          {domainData.workspace_config?.private_library_enabled?.toString()}
                        </Box>
                      ) : (
                        <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>digital_backpack_enabled</TableCell>
                    <TableCell>
                      {!!domainData.features?.digitalbackpack?.toString() ? (
                        <Box sx={{ color: 'success.main' }}>
                          {domainData.features?.digitalbackpack?.toString()}
                        </Box>
                      ) : (
                        <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <b>Domain Summary</b>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>link to domain summary</TableCell>
                    <TableCell>
                      <Link
                        href={`https://settings.teacherdashboard.com/#/d/-${domain.URN}/domain-audit/index`}
                        target="_blank"
                      >
                        Link
                      </Link>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>total_number_students</TableCell>
                    <TableCell>
                      {!!pugsData.number_students ? (
                        <Box sx={{ color: 'success.main' }}>{pugsData.number_students}</Box>
                      ) : (
                        <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                      )}
                    </TableCell>
                    <TableCell>Student count</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>total_number_teachers</TableCell>
                    <TableCell>
                      {!!pugsData.number_teachers ? (
                        <Box sx={{ color: 'success.main' }}>{pugsData.number_teachers}</Box>
                      ) : (
                        <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                      )}
                    </TableCell>
                    <TableCell>Teacher count</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>total_number_classes</TableCell>
                    <TableCell>
                      {!!pugsData.number_classes ? (
                        <Box sx={{ color: 'success.main' }}>{pugsData.number_classes}</Box>
                      ) : (
                        <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                      )}
                    </TableCell>
                    <TableCell>Classes count</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>total_number_schools</TableCell>
                    <TableCell>
                      {!!pugsData.number_schools ? (
                        <Box sx={{ color: 'success.main' }}>{pugsData.number_schools}</Box>
                      ) : (
                        <Box sx={{ color: 'error.main' }}>NO DATA</Box>
                      )}
                    </TableCell>
                    <TableCell>Schools count</TableCell>
                    <TableCell>link to HAC schoolconfig</TableCell>
                    <TableCell>
                      <Link
                        href={`https://td-admin.appspot.com/-${domain.URN}}?page=schoolconfig`}
                        target="_blank"
                      >
                        Link
                      </Link>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    )
  } else {
    return (
      <Box>
        {isPugsDataSuccess ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            There has been an error loading PUGS data. Check that your domain is correct and
            refresh.
          </Box>
        )}
      </Box>
    )
  }
}
