import React, { useState, useEffect, MouseEvent } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import { CircularProgress } from '@material-ui/core'
import './BulkCopy.css'
import { Link } from 'react-router-dom'
import {
  queryTaskDetail,
  queryTaskHistoryClosed,
  queryTaskHistoryOpen,
  queryTaskDetailHistory,
  queryCollectionsNameByID,
  refreshCatalog,
} from '../../../apiCalls/bulk-copy'
import type { TaskHistory } from '../../../apiCalls/bulk-copy'
import { Modal, Button as AntdButton, Table as AntdTable } from 'antd'
import { DownOutlined } from '@ant-design/icons'

interface RowElement {
  workflowId: string
  runId: string
  taskName: string
  status: string
  startTime: string
  closeTime: string
}

interface DetailRowElement {
  workflowName: string
  startedAt: string
  closedAt: string
  status: string
  workflowId: string
  runId: string
  sourceDomain: string
  sourceCollectionName: string
  targetDomain: string
  targetCollectionName: string
  authorisor: string
  authoriseTime: string
  User: string
}
export default function BasicTable() {
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false)
  const [rowsData, setRowsData] = useState<TaskHistory[]>([])
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const [detailRowData, setDetailRowData] = useState<DetailRowElement>()
  const [isDetailLoading, setIsDetailLoading] = useState<boolean>(false)
  const COLUMNS = [' ', 'WORKFLOW ID', 'RUN ID', 'WORK NAME', 'STATUS', 'START TIME', 'END TIME']

  const [closedNextPageToken, setClosedNextPageToken] = useState<string>('')
  const [openNextPageToken, setOpenNextPageToken] = useState<string>('')
  const [isLoadMoreButtonDisabled, setIsLoadMoreButtonDisabled] = useState<boolean>(true)
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState<boolean>(false)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [isModalFail, setIsModalFail] = useState<boolean>(false)

  const fetchHistoryData = async () => {
    const startDate = new Date()
    startDate.setMonth(startDate.getMonth() - 1)
    try {
      let result: TaskHistory[] = []
      const resultClosed: any = await queryTaskHistoryClosed(
        startDate,
        new Date(),
        closedNextPageToken
      )
      const resultOpen: any = await queryTaskHistoryOpen(startDate, new Date(), openNextPageToken)

      setClosedNextPageToken(resultClosed.data.nextPageToken)
      setOpenNextPageToken(resultOpen.data.nextPageToken)

      if (resultClosed.data.executions !== undefined) {
        result = result.concat(resultClosed.data.executions)
      }
      if (resultOpen.data.executions !== undefined) {
        result = result.concat(resultOpen.data.executions)
      }

      setRowsData(rowsData.concat(result))

      if (resultClosed.data.nextPageToken !== '' || resultOpen.data.nextPageToken !== '') {
        setIsLoadMoreButtonDisabled(false)
      } else {
        setIsLoadMoreButtonDisabled(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setIsPageLoading(true)
    fetchHistoryData()
    setIsPageLoading(false)
    // it requires the func of fetchHistoryData to be added the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createData = (
    workflowId: string,
    runId: string,
    taskName: string,
    status: string,
    startTime: string,
    closeTime: string
  ) => {
    return {
      icon: 'icon',
      workflowId,
      runId,
      taskName,
      status,
      startTime: moment(startTime).format('MMMM Do YYYY, h:mm:ss a'),
      closeTime: moment(closeTime).format('MMMM Do YYYY, h:mm:ss a'),
    }
  }

  const createDetailData = (detail: DetailRowElement) => {
    return {
      'workflow name': detail.workflowName,
      'started at': detail.startedAt,
      'closed at': detail.closedAt,
      status: detail.status,
      'workflow id': detail.workflowId,
      'run id': detail.runId,
      'source domain': detail.sourceDomain,
      'source collection': detail.sourceCollectionName,
      'target domain': detail.targetDomain,
      'target collection': detail.targetCollectionName,
      authorisor: detail.authorisor,
      'authorise time': detail.authoriseTime,
      user: detail.User,
    }
  }

  const rows: RowElement[] = rowsData.map((row: TaskHistory) => {
    return createData(
      row.execution.workflowId,
      row.execution.runId,
      row.type.name,
      row.status,
      row.startTime,
      row.closeTime
    )
  })

  const handleOkOrCancel = () => {
    setIsVisible(false)
  }

  const RenderModal = (task: RowElement) => async (event: MouseEvent<HTMLTableCellElement>) => {
    setIsDetailLoading(true)
    setIsVisible(true)
    const detail = await queryTaskDetail(task.workflowId, task.runId)
    const history = await queryTaskDetailHistory(task.workflowId, task.runId)
    const sourceCollectionID =
      history.data.history.events[0].details.input.payloads[0].SourceCollectionID
    const targetCollectionID =
      history.data.history.events[0].details.input.payloads[0].TargetCollectionID
    const collectionNames = await queryCollectionsNameByID(
      [sourceCollectionID, targetCollectionID],
    )
    let sourceName: string = '',
      targetName: string = ''
    collectionNames.forEach(collectionItem => {
      if (sourceCollectionID === collectionItem.id) {
        sourceName = collectionItem.name
      } else if (targetCollectionID === collectionItem.id) {
        targetName = collectionItem.name
      }
    })

    setDetailRowData({
      workflowName: detail.data.workflowExecutionInfo.type.name,
      startedAt: moment(detail.data.workflowExecutionInfo.startTime).format(
        'MMMM Do YYYY, h:mm:ss a'
      ),
      closedAt: moment(detail.data.workflowExecutionInfo.closeTime).format(
        'MMMM Do YYYY, h:mm:ss a'
      ),
      status: detail.data.workflowExecutionInfo.status,
      workflowId: detail.data.workflowExecutionInfo.execution.workflowId,
      runId: detail.data.workflowExecutionInfo.execution.runId,
      sourceDomain: history.data.history.events[0].details.input.payloads[0].SourceDomain,
      sourceCollectionName: sourceName,
      targetDomain: history.data.history.events[0].details.input.payloads[0].TargetDomain,
      targetCollectionName: targetName,
      authorisor: history.data.history.events[0].details.input.payloads[0].Authorisor,
      authoriseTime: moment(
        history.data.history.events[0].details.input.payloads[0].AuthoriseTime
      ).format('MMMM Do YYYY, h:mm:ss a'),
      User: history.data.history.events[0].details.input.payloads[0].User,
    })
    setIsDetailLoading(false)
  }

  const handleLoadMoreButtonClick = async (event: MouseEvent<HTMLButtonElement>) => {
    setIsLoadMoreLoading(true)
    await fetchHistoryData()
    setIsLoadMoreLoading(false)
  }

  const RenderTableRowsElement = (row: RowElement) => {
    const ret: Array<JSX.Element> = []

    for (const [key, value] of Object.entries(row)) {
      switch (key) {
        case 'icon':
          ret.push(
            <TableCell key={key} align="right" onClick={RenderModal(row)}>
              <AntdButton type="primary">
                <DownOutlined />
              </AntdButton>
            </TableCell>
          )
          break
        case 'status':
          ret.push(
            <TableCell
              key={key}
              align="right"
              style={value === 'Failed' ? { color: '#F30', fontWeight: 'bold' } : {}}
            >
              {value}
            </TableCell>
          )
          break
        default:
          ret.push(
            <TableCell key={key} align="right">
              {value}
            </TableCell>
          )
      }
    }
    return ret
  }

  const RenderTableRows = rows.map((row: RowElement, index: number) => (
    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      {RenderTableRowsElement(row)}
    </TableRow>
  ))

  const RenderTableColumns = COLUMNS.map((column: string) => {
    return (
      <TableCell key={column} align="right">
        {column}
      </TableCell>
    )
  })

  const handleRefreshCatalogBtn = async () => {
    try {
      await refreshCatalog()
      setIsModalFail(false)
    } catch (e) {
      setIsModalFail(true)
    } finally {
      setIsModalVisible(true)
    }
  }

  const RenderRefreshModalContent = !isModalFail ? (
    process.env.REACT_APP_BUILD_ENV === 'test' ? (
      <p>
        Successfully updated master catalog (test). View spreadsheet{' '}
        <a
          href="https://docs.google.com/spreadsheets/d/1qCda4zKKzSvky9SSJ7E6U6yNw48TdChhdN7mYe-oq7U"
          target="_blank"
          rel="noreferrer"
        >
          here.
        </a>
      </p>
    ) : (
      <p>
        Successfully updated master catalog (prod). View spreadsheet{' '}
        <a
          href="https://docs.google.com/spreadsheets/d/1ttOuvwfPmsfR52ETU7EmTvzYXD93dNPhUN4jjk4B0gc"
          target="_blank"
          rel="noreferrer"
        >
          here.
        </a>
      </p>
    )
  ) : (
    <p>An error has occurred.</p>
  )

  const RenderModalTable = () => {
    if (isDetailLoading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </div>
      )
    }
    const dataSource = []
    if (detailRowData !== undefined) {
      for (const [key, val] of Object.entries(createDetailData(detailRowData))) {
        dataSource.push({
          key: key,
          name: key,
          value: val,
        })
      }
    }

    return (
      <AntdTable
        pagination={false}
        dataSource={dataSource}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
          },
        ]}
      />
    )
  }

  const RenderTableButtonGroup = (
    <div className="bottom__pagination" style={{ marginTop: '10px', marginBottom: '10px' }}>
      <AntdButton
        className="bottom__new-task-btn bottom__btn-margin-left"
        size="large"
        onClick={handleRefreshCatalogBtn}
        type="primary"
      >
        Refresh Catalog
      </AntdButton>
      <Modal
        title="Refresh Master Catalog"
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false)
        }}
        onCancel={() => setIsModalVisible(false)}
      >
        {RenderRefreshModalContent}
      </Modal>
      <AntdButton
        className="bottom__new-task-btn bottom__btn-margin-left"
        size="large"
        disabled={isLoadMoreButtonDisabled}
        onClick={handleLoadMoreButtonClick}
        loading={isLoadMoreLoading}
        type="primary"
      >
        Load More
      </AntdButton>

      <Link className="bottom__btn-margin-left" to="/internal-admin/new-task-copy">
        <AntdButton className="bottom__new-task-btn" size="large" type="primary">
          New Task
        </AntdButton>
      </Link>
    </div>
  )

  if (isPageLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>{RenderTableColumns}</TableRow>
          </TableHead>
          <TableBody>{RenderTableRows}</TableBody>
        </Table>
        <div className="bottom">{RenderTableButtonGroup}</div>
      </TableContainer>
      <Modal
        visible={isVisible}
        title="Details"
        onOk={handleOkOrCancel}
        onCancel={handleOkOrCancel}
        footer={[
          <AntdButton key={'close'} type="primary" onClick={handleOkOrCancel}>
            Close
          </AntdButton>,
        ]}
      >
        {RenderModalTable()}
      </Modal>
    </>
  )
}
