import './TDSupportUtilities.css'
import GeneralLoad from './Components/GeneralLoad'
import SIS from './Components/SIS'
import Links from './Components/Links'

export default function TDSupportUtilities() {
  return (
    <div id="container">
      <GeneralLoad />
      <SIS />
      <Links />
    </div>
  )
}
