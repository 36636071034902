import _ from 'lodash'
import { Data } from '../app/types'
import { RootState } from '../store'

export const getSelectedUser = (state: RootState) => _.get(state, `app.${Data.User}.data`)

export const getSelectedUserID = (state: RootState) =>
  _.get(state, `app.${Data.User}.data.external_id`)

export const getExtensionLogs = (state: RootState) => _.get(state, `app.${Data.ExtensionLogs}.data`)

export const getOldExtensionLogs = (state: RootState) =>
  _.get(state, `app.${Data.OldExtensionLogs}.data`)

export const getStudentClasses = (state: RootState) =>
  _.get(state, `app.${Data.StudentClasses}.data`)

export const getSessionStateChanges = (state: RootState) =>
  _.get(state, `app.${Data.SessionStateChanges}.data`)

export const getUserTasks = (state: RootState) => _.get(state, `app.${Data.UserTasks}.data`)

export const getTaskGroupParent = (state: RootState) =>
  _.get(state, `app.${Data.TaskGroup}.data.Parent`)
export const getTaskGroupChildren = (state: RootState) =>
  _.get(state, `app.${Data.TaskGroup}.data.Children`)
