import _ from 'lodash'
import moment from 'moment'

export const getErrorStringFromErrorOrResponse = error => {
  return _.get(error, 'response')
    ? `${_.get(error, 'response.status')}:${_.get(error, 'response.data.error_code')}`
    : `${_.get(error, 'name')}: ${_.get(error, 'message')}`
}

export const checkExpiration = time => {
  const sessionDetails = JSON.parse(localStorage.getItem('session_details') || '{}')
  const { rt_expires } = sessionDetails
  const timeToCheck = (time) ? time : rt_expires
  const timeDifference = timeToCheck - moment().add(1, 'minutes').unix()
  return timeDifference > 0
}

export const handleLocalStorage = ({ isSignedIn }) => {
  const sessionDetails = { user_signedIn: isSignedIn }
  const rtExpiresAt = moment()
    .add(process.env.REACT_APP_REFRESH_TOKEN_DURATION_MINUTES, 'milliseconds')
    .unix()
  if (isSignedIn) {
    Object.assign(sessionDetails, {
      rt_expires: rtExpiresAt,
    })
  }

  localStorage.setItem('session_details', JSON.stringify(sessionDetails))
}
