import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75vh',
  },
  logoWrapper: {
    width: '305px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    '& img': {
      width: '100%',
    },
    '& h2': {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#334158',
      margin: '8px 0 16px 0',
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    width: '303px',
  },
  loadingBar: {
    width: '200px',
    height: '40px',
    color: '#334158',
  },
  signOutButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100px',
    color: '#707078',
    cursor: 'pointer',
    '&:hover': {
      color: '#000',
    },
  },
})

export default useStyles
