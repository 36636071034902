import 'whatwg-fetch'
import axios from 'axios'

export const createCurrentAuthHeader = (token) => {
  return `Bearer ${token}`
}

export const postSendEvent = data => {
  const url = `${process.env.REACT_APP_API_HAPARA_URL}/troubleshooting/send`
  return axios.post(url, { data })
}
