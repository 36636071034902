import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Panel from '../../components/Panel/Panel'
import Load from '../../components/Actions/Load'
import TimePicker, {
 useTimeRange,
 DEFAULT_TIMEZONE,
} from '../../components/Actions/TimePicker'
import { Data } from '../../state/app/types'
import { useSelector } from 'react-redux'
import {
 getSelectedUser,
 getExtensionLogs,
 getStudentClasses,
 getSessionStateChanges,
} from '../../state/user-management/selectors'
import { getQuery } from '../../state/app/selectors'
import { getDomain } from '../../state/domain-management/selectors'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Modal from '@material-ui/core/Modal'
import Alert from '@material-ui/lab/Alert'
import ReactJson from 'react-json-view'
import _ from 'lodash'

import {
   Button,
   Chip,
   Container,
   Grid,
   Paper,
   Switch,
} from '@material-ui/core'
import moment from 'moment'

const LATEST_EXTENSION_VERSION = '4.1.32'

const useStyles = makeStyles(theme => ({
  chipsContainer: {
    padding: theme.spacing(8),
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(8),
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 600,
    maxHeight: 600,
    overflow: 'scroll',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    width: '100%',
  },
  configuration: {
    display: 'flex',
  },
  slider: {
    maxWidth: 200,
  },
  table: {
    minWidth: 650,
  },
  tableCellInfo: {
    wordBreak: 'break-all',
  },
}))

const getData = (row: any, info: any) => {
  if (row.Event) {
    return row.Modification
  }
  const extra = JSON.parse(row[11])
  if (row[0] === 'extensionLog') {
    let message = ''
    if (Array.isArray(extra)) {
      message = `[${extra[1]}] ${extra.slice(3).join(' ')}`
      if (extra[4] && extra[4].startsWith('{')) {
        message = `[${extra[1]}] ${extra.slice(3, 4).join(' ')} ${extra.slice(5).join(' ')}`
      }
    } else {
      message = '' + extra // has to be string
    }
    if (message === '[hlstate] Session received  undefined ') {
      return '[hlstate] Got no active sessions from state'
    }
    return message
  }
  if (row[0] === 'handleStudentOnlineMessage') {
    return `${extra.instance_id} received student online`
  }
  if (row[0] === 'handleStudentOfflineMessage') {
    return `${extra.instance_id} received student offline`
  }
  if (row[0] === 'extensionBusConnect') {
    return 'Extension bus connect'
  }
  if (row[0] === 'extensionApiError') {
    return row[0] + '::' + info?.apiPath + ':' + info?.error_code
  }
  return row[0]
}

const getTime = (row: any, timeZone: string) => {
  if (row.Event) {
    return moment(row.Time).tz(timeZone).format('HH:mm:ss')
  }
  const extra = JSON.parse(row[11])
  if (row[0] === 'extensionLog') {
    if (Array.isArray(extra)) {
      return moment(extra[0]).tz(timeZone).format('HH:mm:ss')
    }
  }
  return moment(row[10]).tz(timeZone).format('HH:mm:ss')
}

const getTimeLong = (row: any) => {
  if (row.Event) {
    return moment(row.Time).toDate().getTime()
  }
  const extra = JSON.parse(row[11])
  if (row[0] === 'extensionLog') {
    return extra[0]
  }
  return moment(row[10]).toDate().getTime()
}

const getInfo = (row: any) => {
  if (row.Event) {
    return row
  }
  if (row[0] === 'extensionLog') {
    const extra = JSON.parse(row[11])
    if (extra[4] && extra[4].startsWith('{')) {
      return JSON.parse(extra[4])
    }
  }
  if (row[11] && row[11].startsWith('{')) {
    return JSON.parse(row[11])
  }
  return null
}

function hashCode(str: string) {
  // java String#hashCode
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

function intToRGB(i: number) {
  var c = (i & 0x00ffffff).toString(16).toUpperCase()

  return '#' + '00000'.substring(0, 6 - c.length) + c
}

const ignore = (row: any) => {
  //we want to ignore some as they do not add any info.
  //they will be removed from extension next version
  if (row[0] === 'extensionSendData') {
    return true
  }
  if (row[0] === 'extensionLog') {
    const extra = JSON.parse(row[11])
    if (extra[1] === 'pusher/messaging') {
      return true
    }
  }
  return false
}

const getBackgroundColor = (row: any, info: any) => {
  if (row.Event) {
    return 'lightblue'
  }
  if (row[0] === 'reportIssue') {
    return 'pink'
  }
  if (!info) {
    return 'white'
  }
  if (info.name === 'extensionApiError') {
    return 'orange'
  }
  return 'white'
}

interface CommonStudentInfo {
  clientIp: string
  instanceID: string[]
  platform: string
  extensionVersion: string[]
  hlBus: string
  chromeVersion: string
}

const getAlertsFromInfo = (commonInfo: CommonStudentInfo) => {
  const alerts = []
  if (commonInfo.extensionVersion.length === 0) {
    alerts.push(
      <Alert key="no-logs" severity="error">
        No extension logs detected, please check the old system for logs
      </Alert>
    )
    return alerts
  }
  if (commonInfo.instanceID.length > 1) {
    alerts.push(
      <Alert key="multiple-instances" severity="info">
        There's more than one instance of student device in the mix.
      </Alert>
    )
  }

  if (commonInfo.extensionVersion[0] !== LATEST_EXTENSION_VERSION) {
    alerts.push(
      <Alert key="extension-version" severity="error">
        The student is on an old extension version {commonInfo.extensionVersion[0]}, the latest
        extension is {LATEST_EXTENSION_VERSION}
      </Alert>
    )
  }
  if (commonInfo.extensionVersion.length > 1 && commonInfo.instanceID.length === 1) {
    alerts.push(
      <Alert key="multiple-versions" severity="info">
        Extension version was updated during the time
      </Alert>
    )
  }
  return alerts
}

export default function HighlightsLogs() {
  const classes = useStyles()
  const user = useSelector(getSelectedUser) as any
  const domain = useSelector(getDomain)
  const extensionLogs = useSelector(getExtensionLogs)
  const studentClasses = useSelector(getStudentClasses) as any
  const sessionStateChanges = useSelector(getSessionStateChanges)

  const query = useSelector(getQuery)
  const userID = user?.external_id
  const [timezone, setTimezone] = useState(DEFAULT_TIMEZONE)
  const [timeRangeFrom, timeRangeTo] = useTimeRange()
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [infoModalContent, setInfoModalContent] = useState({})
  const [data, setData] = useState([])
  const [isErrorFilter, setIsErrorFilter] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsErrorFilter(event.target.checked)
  }

  const commonInfo: CommonStudentInfo = {
    clientIp: '',
    instanceID: [],
    platform: '',
    extensionVersion: [],
    hlBus: '',
    chromeVersion: '',
  }

  ;(extensionLogs || []).forEach((f: any) => {
    if (f[1] === 'extension') {
      commonInfo.clientIp = f[9]
      commonInfo.chromeVersion = f[4]
      const extensionVersions = commonInfo.extensionVersion.join(',')
      if (extensionVersions.indexOf(f[5]) < 0) {
        commonInfo.extensionVersion.push(f[5])
      }
      commonInfo.hlBus = f[7]
      commonInfo.platform = f[3]
      const instances = commonInfo.instanceID.join(',')
      if (instances.indexOf(f[2]) < 0) {
        commonInfo.instanceID.push(f[2])
      }
    }
  })

  const processedData = useCallback(() => {
    return (extensionLogs || []).filter((r: any) => !ignore(r)).concat(sessionStateChanges || [])
  }, [extensionLogs, sessionStateChanges])

  useEffect(() => {
    let filteredData = processedData()

    if (isErrorFilter) {
      filteredData = filteredData.filter((item: any) => {
        if (item[0] === 'extensionApiError') {
          return true
        }
        return false
      })
    }

    setData(filteredData)
  }, [isErrorFilter, processedData])

  return (
    <div className={classes.root}>
      <h2>Highlights User logs</h2>
      <Modal className={classes.modal} open={infoModalOpen} onClose={() => setInfoModalOpen(false)}>
        <div className={classes.paper}>
          <ReactJson src={infoModalContent} />
        </div>
      </Modal>
      <Panel
        header={`User Info: ${
          _.get(user, 'roles').indexOf('student') < 0 ? '' : 'STUDENT  '
        } ${_.get(user, 'primary_email')}`}
      >
        <ReactJson src={user} collapsed={true} style={{ width: '100%' }} />
        <a
          rel="noreferrer"
          href={`https://hugs.teacherdashboard.com/admin/_/#/main/user/urn/${_.get(
            user,
            'primary_email'
          )}/raw`}
          target="_blank"
        >
          Hugs user
        </a>
      </Panel>
      <Panel header={`Domain Info: ${_.get(domain, 'URN')}`}>
        <ReactJson src={domain} collapsed={true} style={{ width: '100%' }} />
        <a
          rel="noreferrer"
          href={`https://hugs.teacherdashboard.com/admin/_/#/main/group/urn/${_.get(
            domain,
            'URN'
          )}/raw`}
          target="_blank"
        >
          Hugs domain
        </a>
      </Panel>
      <TimePicker timezone={timezone} onTimezoneChange={setTimezone} />
      <Load path={Data.StudentClasses} params={{ userID }}>
        {studentClasses && (
          <Load
            path={Data.SessionStateChanges}
            params={{
              from: timeRangeFrom,
              to: timeRangeTo,
              classURNs: studentClasses?.map((c: any) => c.ClassURN),
            }}
          >
            <Load
              path={Data.ExtensionLogs}
              params={{
                student: userID,
                runTime: query.runTime || 0,
                from: timeRangeFrom,
                to: timeRangeTo,
              }}
            >
              {getAlertsFromInfo(commonInfo)}
              <Panel header="Student">
                <Grid container justify="flex-start" spacing={2}>
                  <Chip
                    label={`IP: ${commonInfo.clientIp}`}
                    variant="outlined"
                    color="primary"
                  ></Chip>
                  <Chip label={`${commonInfo.platform}`} variant="outlined" color="primary"></Chip>
                  <Chip
                    label={`Chrome: ${commonInfo.chromeVersion}`}
                    variant="outlined"
                    color="primary"
                  ></Chip>
                  <Chip
                    label={`Extension: ${commonInfo.extensionVersion}`}
                    variant="outlined"
                    color="primary"
                  ></Chip>
                  <Chip
                    label={`HL Bus: ${commonInfo.hlBus}`}
                    variant="outlined"
                    color="primary"
                  ></Chip>
                  {commonInfo.instanceID.map(i => {
                    return (
                      <Chip
                        key={i}
                        label={`instance: ${i.substr(0, 10)}`}
                        variant="outlined"
                        color="primary"
                      ></Chip>
                    )
                  })}
                </Grid>
              </Panel>
              <Panel header="Extension logs">
                <Container maxWidth={false}>
                  <Switch checked={isErrorFilter} onChange={handleChange} />
                  Filter API Errors
                </Container>
                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableBody>
                      {_.sortBy(data, [
                        (row: any) => {
                          return -getTimeLong(row)
                        },
                      ]).map((row: any, index: number) => {
                        const info = getInfo(row)
                        return (
                          <TableRow
                            key={index}
                            style={{ backgroundColor: getBackgroundColor(row, info) }}
                          >
                            <TableCell align="left">{getTime(row, timezone)}</TableCell>
                            <TableCell
                              align="left"
                              style={{
                                backgroundColor: row[6] === 'student' ? 'lightgray' : 'lightgreen',
                              }}
                            >
                              {row[6]}
                            </TableCell>
                            {commonInfo.instanceID?.length > 1 && (
                              <TableCell
                                align="left"
                                style={{
                                  backgroundColor: row.Event ? 'white' : intToRGB(hashCode(row[2])),
                                }}
                              >
                                {row.Event ? '' : row[2].substr(0, 5)}
                              </TableCell>
                            )}
                            <TableCell className={classes.tableCellInfo} align="left">
                              {getData(row, info)}
                            </TableCell>
                            <TableCell align="right">
                              {getInfo(row) ? (
                                <Button
                                  onClick={() => {
                                    setInfoModalContent(info)
                                    setInfoModalOpen(true)
                                  }}
                                >
                                  More
                                </Button>
                              ) : (
                                ''
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Panel>
            </Load>
          </Load>
        )}
      </Load>
    </div>
  )
}
