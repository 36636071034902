import React from 'react'

export enum SideBarTypes {
  DIVIDER = 'divider',
  MENU_ITEM = 'menu-item',
}

export interface SideBarItem {
  icon: React.ReactNode
  title: string
  path: string
  id: string
  type: SideBarTypes
}

export enum Data {
  Buses = 'buses',
  Domain = 'selectedDomain',
  DomainDigitalBackpackCurators = 'selectedDomain.backpackGTXcel',
  BusDetails = 'buses.details',
  User = 'selectedUser',
  ExtensionLogs = 'selectedUser.extensionLogs',
  OldExtensionLogs = 'selectedUser.oldExtensionLogs',
  StudentClasses = 'selectedUser.studentClasses',
  SessionStateChanges = 'selectedUser.sessionStateChanges',
  UserTasks = 'selectedUser.tasks',
  TaskGroup = 'taskGroup',
  StatusDashboard = 'statusDashboard',
  ClassInfo = 'classInfo',
}

export enum Actions {
  MoveDomains = 'move-domains',
  MoveOneDomain = 'move-domain',
  AddBackPackCurator = 'add-backpack-curator',
  CloseIncident = 'close-incident',
}
