import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { useSelector } from 'react-redux'
import { getData } from '../../state/app/selectors'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core'
import _ from 'lodash'
import ActionDialog from '../../components/Actions/ActionDialog'
import { Actions, Data } from '../../state/app/types'
import Panel from '../../components/Panel/Panel'
import Details from '../../components/Actions/Details'
import BusDetails from './BusDetails'
import { ELEMENTS } from '../../state/internal-admin/types'
import Load from '../../components/Actions/Load'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 10,
    overflow: 'scroll',
    height: '100%',
  },
  title: {
    flexGrow: 1,
  },
  card: {
    minWidth: 180,
    height: 200,
  },
  cardContent: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

interface Bus {
  _id: string
  domains_count: number
}

const BusCard: React.FunctionComponent<{
  bus: Bus
  setSelectedBus: (a: Bus) => void
  setMoveOpened: (a: boolean) => void
  setDetailsOpened: (a: Bus) => void
}> = ({ bus, setSelectedBus, setMoveOpened, setDetailsOpened }) => {
  const classes = useStyles()
  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h5" component="h3">
          {bus._id}
        </Typography>
        <Typography variant="h3" component="h4">
          {bus.domains_count}
        </Typography>
        <Typography gutterBottom variant="body2" component="p">
          Domains
        </Typography>
      </CardContent>
      <Divider />
      <CardActions>
        {bus._id && (
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setSelectedBus(bus)
              setMoveOpened(true)
            }}
          >
            Move domains
          </Button>
        )}
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            setDetailsOpened(bus)
          }}
        >
          View Details
        </Button>
      </CardActions>
    </Card>
  )
}

export default function HighlightsBusManagement() {
  const classes = useStyles()
  const buses = useSelector(getData(Data.Buses))
  const [selectedBus, setSelectedBus] = useState<Bus | undefined>(undefined)
  const [moveOpened, setMoveOpened] = useState(false)
  const [moveFormValues, setMoveFormValues] = useState({
    domainsNumber: 1,
    destination: 'hydrogen',
  })
  const [detailsOpened, setDetailsOpened] = useState<Bus | undefined>(undefined)
  const handleMoveClose = () => {
    setMoveOpened(false)
    setSelectedBus(undefined)
  }
  return (
    <Load path={Data.Buses}>
      {buses && (
        <div className={classes.root}>
          <Panel header={'Links to resource usage'}>
            <p>
              <a
                rel="noreferrer"
                href="https://console.cloud.google.com/monitoring/metrics-explorer?pageState=%7B%22xyChart%22:%7B%22dataSets%22:%5B%7B%22timeSeriesFilter%22:%7B%22filter%22:%22metric.type%3D%5C%22kubernetes.io%2Fcontainer%2Fcpu%2Flimit_utilization%5C%22%20resource.type%3D%5C%22k8s_container%5C%22%20resource.label.%5C%22cluster_name%5C%22%3D%5C%22main-cluster%5C%22%20resource.label.%5C%22container_name%5C%22%3Dmonitoring.regex.full_match(%5C%22hlbus%5C%22)%22,%22minAlignmentPeriod%22:%2260s%22,%22aggregations%22:%5B%7B%22perSeriesAligner%22:%22ALIGN_MEAN%22,%22crossSeriesReducer%22:%22REDUCE_NONE%22,%22groupByFields%22:%5B%5D%7D,%7B%22crossSeriesReducer%22:%22REDUCE_NONE%22%7D%5D%7D,%22targetAxis%22:%22Y1%22,%22plotType%22:%22LINE%22%7D%5D,%22options%22:%7B%22mode%22:%22COLOR%22%7D,%22constantLines%22:%5B%5D,%22timeshiftDuration%22:%220s%22,%22y1Axis%22:%7B%22label%22:%22y1Axis%22,%22scale%22:%22LINEAR%22%7D%7D,%22isAutoRefresh%22:true,%22timeSelection%22:%7B%22timeRange%22:%221w%22%7D%7D&project=hapara-microservices-prod"
                target="_blank"
              >
                Stackdriver CPU usage graph
              </a>
            </p>
            <p>
              <a
                rel="noreferrer"
                href="https://ui.honeycomb.io/hapara-2f/board/BtF9izFmDMJ/Highlights-Buses"
                target="_blank"
              >
                Honeycomb Usage Dashboard
              </a>
            </p>
          </Panel>
          <Panel header={'Domains per bus'}>
            <Grid container spacing={3}>
              {_.sortBy(buses, b => ELEMENTS.indexOf(b._id))
                .filter(b => ELEMENTS.indexOf(b._id) >= 0)
                .map(b => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={b._id}>
                      <BusCard
                        bus={b}
                        setSelectedBus={(bus: Bus) => setSelectedBus(bus)}
                        setMoveOpened={setMoveOpened}
                        setDetailsOpened={setDetailsOpened}
                      />
                    </Grid>
                  )
                })}
            </Grid>
          </Panel>

          <Panel header={'Unassigned domains'}>
            <Grid container spacing={3}>
              {buses
                .filter((b: Bus) => ELEMENTS.indexOf(b._id) < 0)
                .map((b: Bus) => (
                  <Grid item xs={12} sm={6} md={4} lg={2} xl={2} key={b._id}>
                    <BusCard
                      bus={b}
                      setSelectedBus={setSelectedBus}
                      setMoveOpened={setMoveOpened}
                      setDetailsOpened={setDetailsOpened}
                    />
                  </Grid>
                ))}
            </Grid>
          </Panel>
          <Details
            header={`Bus ${detailsOpened ? detailsOpened._id : ''}`}
            isOpened={!!detailsOpened}
            handleClose={() => setDetailsOpened(undefined)}
          >
            {detailsOpened && <BusDetails bus={detailsOpened} />}
          </Details>
          <ActionDialog
            isOpened={moveOpened && selectedBus != null}
            handleClose={handleMoveClose}
            action={Actions.MoveDomains}
            getFormData={() => {
              return { ...moveFormValues, source: (selectedBus || {})._id }
            }}
          >
            <DialogTitle id="move-domains-form-dialog-title">Move domains</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Move the specified number of domains from {selectedBus && selectedBus._id}
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="domainsNumber"
                label="Number of domains"
                type="number"
                defaultValue={moveFormValues.domainsNumber}
                onChange={e => {
                  setMoveFormValues({ ...moveFormValues, domainsNumber: parseInt(e.target.value) })
                }}
                fullWidth
              />
              <Autocomplete
                id="destination"
                options={ELEMENTS}
                getOptionLabel={option => option}
                defaultValue={moveFormValues.destination}
                onChange={(e, v) => {
                  setMoveFormValues({ ...moveFormValues, destination: v || '' })
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="dense"
                    label="Select destination bus"
                    variant="outlined"
                  />
                )}
              />
            </DialogContent>
          </ActionDialog>
        </div>
      )}
    </Load>
  )
}
