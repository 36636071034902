import _ from 'lodash'
import { createSelector } from 'reselect'

export const getJwtInfo = state => _.get(state, 'user.jwtInfo')

export const getJwtTokenAtExpires = createSelector(getJwtInfo, jwtInfo =>
  _.get(jwtInfo, 'at_expires', null)
)

export const getJwtTokenType = createSelector(getJwtInfo, jwtInfo => _.get(jwtInfo, 'type', null))

export const isLoadingJwt = state => _.get(state, 'user.jwtLoading')
export const IsFailedToLoadJwt = state => _.get(state, 'user.jwtError')
export const isLoadedJwt = state => _.get(state, 'user.jwtLoaded')
export const isUserLoggedIn = state => _.get(state, 'user.userLoggedIn')
