import React, { useState } from 'react'
import SideBar from '../../components/Authentication/Layout/SideBar'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import ClassIcon from '@material-ui/icons/Class'

const items = [
  {
    title: 'Class Summary',
    path: '/summary',
    id: 'summary',
    icon: <ClassIcon />,
  },
]

export default function ClassManagementNavigation({ path }) {
  const [open, setOpen] = useState(true)
  const { id } = useParams()

  return (
    <SideBar
      open={open}
      setOpen={setOpen}
      selected={id}
      items={items.map(i => ({ ...i, path: path + i.path }))}
    />
  )
}

ClassManagementNavigation.propTypes = {
  path: PropTypes.string,
}
