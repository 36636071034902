import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import NavBar from '../../components/Authentication/Layout/NavBar'
import Load from '../../components/Actions/Load'
import { Route, Redirect, Switch, useParams } from 'react-router-dom'
import UserSummary from './UserSummary'
import UserTasks from './UserTasks'
import UserSingleTaskGroup from './UserSingleTaskGroup'
import HighlightsLogs from './HighlightsLogs'
import { Data } from '../../state/app/types'
import UserManagementNavigation from './UserManagementNavigation'
import { getSelectedUser } from '../../state/user-management/selectors'
import { useSelector } from 'react-redux'
import OldLogs from './OldLogs'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  page: {
    width: '100%',
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

type UserNavigationParams = {
  user: string
}
export default function UserManagementView() {
  const classes = useStyles()
  const { user } = useParams<UserNavigationParams>()
  const selectedUser = useSelector(getSelectedUser) as any
  const location = useLocation()
  const query = location.search as string
  return (
    <div className={classes.root}>
      <NavBar subheader={user} />
      <Load path={Data.User} params={{ id: user }}>
        {selectedUser && (
          <Load path={Data.Domain} params={{ domain: selectedUser.domains[0] || '' }}>
            <UserManagementNavigation path={`/user-management/${user}`} query={query} />
            <div className={classes.page}>
              {/* Reserves space */}
              <Toolbar />
              <Switch>
                <Route path={`/user-management/:user/summary`} component={UserSummary} />
                <Route path={`/user-management/:user/activity`} component={UserSummary}></Route>
                <Route path={`/user-management/:user/tasks`} component={UserTasks} />
                <Route
                  path={`/user-management/:user/task/:taskID`}
                  component={UserSingleTaskGroup}
                />
                <Route path={`/user-management/:user/hl-logs`} component={HighlightsLogs} />
                <Route path={`/user-management/:user/old-logs`} component={OldLogs} />
                <Redirect from={'/user-management/:user/'} to={`/user-management/:user/summary`} />
              </Switch>
            </div>
          </Load>
        )}
      </Load>
    </div>
  )
}
