import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import NavBar from '../../components/Authentication/Layout/NavBar'
import ClassManagementNavigation from './ClassManagementNavigation'
import Load from '../../components/Actions/Load'
import { Route, Redirect, Switch, useParams } from 'react-router-dom'
import ClassSummary from './ClassSummary'
import { Data } from '../../state/app/types'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  page: {
    width: '100%',
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

export default function ClassManagementView() {
  const classes = useStyles()
  const { class_urn } = useParams()
  return (
    <div className={classes.root}>
      <NavBar subheader={class_urn} />
      <Load path={Data.ClassInfo} params={{ class_urn }}>
        <ClassManagementNavigation path={`/class-management/${class_urn}`} />
        <div className={classes.page}>
          {/* Reserves space */}
          <Toolbar />
          <Switch>
            <Route path={`/class-management/:class_urn/summary`} component={ClassSummary} />
            <Redirect
              from={'/class-management/:class_urn/'}
              to={`/class-management/:class_urn/summary`}
            />
          </Switch>
        </div>
      </Load>
    </div>
  )
}
