import React from 'react'
import { useSelector } from 'react-redux'
import ReactJson from 'react-json-view'
import { makeStyles } from '@material-ui/core/styles'
import { getClassInfo } from '../../state/class-management/selectors'

const useStyles = makeStyles(theme => ({
  reactJson: {
    marginTop: 30,
  },
}))

const jsonViewer = classDetails => {
  return <ReactJson src={classDetails} />
}

export default function ClassSummary() {
  const classes = useStyles()
  const clInfo = useSelector(getClassInfo)
  return (
    <>
      <div>Class Details</div>
      <div className={classes.reactJson}>{jsonViewer(clInfo)}</div>
    </>
  )
}
