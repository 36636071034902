import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Button, ButtonGroup, Grid } from '@material-ui/core'

import Panel from '../Panel/Panel'
import { getQuery } from '../../state/app/selectors'
import { getDomain } from '../../state/domain-management/selectors'
import { useLocation, useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
  pressed: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    boxShadow: 'inset 0px 0px 5px #c1c1c1',
    outline: 'none',
  },
}))

// local method to parse de-facto global storage
function useQueryParams(beforeRange : number = 60): [number, number, number] {
  const query = useSelector(getQuery)
  let timestamp = moment().startOf('hour').toDate().getTime()
  let before = beforeRange
  let after = 60 //1 hour

  if (query.timestamp) {
    timestamp = parseInt(query.timestamp as string)
  }
  if (query.before) {
    before = parseInt(query.before as string)
  }
  if (query.after) {
    after = parseInt(query.after as string)
  }
  return [timestamp, before, after]
}

// Expose time range as from/to timestamps
export function useTimeRange(beforeRange : number = 60): [number, number] {
  const [timestamp, before, after] = useQueryParams(beforeRange)
  const from = timestamp - before * 60 * 1000
  const to = timestamp + after * 60 * 1000
  return [from, to]
}

export const DEFAULT_TIMEZONE = 'Pacific/Auckland'

export default function TimePicker({
  timezone,
  onTimezoneChange,
  onBeforeRangeChange,
}: {
  timezone: string
  onTimezoneChange: React.Dispatch<React.SetStateAction<string>>
  onBeforeRangeChange?: React.Dispatch<React.SetStateAction<number>>
}) {
  const classes = useStyles()

  const history = useHistory()
  const location = useLocation()
  const domain = useSelector(getDomain)

  const [timestamp, before, after] = useQueryParams()
  const [timeView, setTimeView] = useState('NZ')
  const [timeState, setTimeState] = useState(timestamp)

  const updateTimeView = (view: string): void => {
    setTimeView(view)
    const tz = view === 'NZ' ? 'Pacific/Auckland' : view === 'Domain' ? domain.Timezone : 'Etc/UTC'
    onTimezoneChange(tz)
  }
  return (
    <Panel header="Time Range">
      <Grid container justify="space-around" alignItems="center">
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button
            className={timeView === 'UTC' ? classes.pressed : ''}
            onClick={() => updateTimeView('UTC')}
          >
            UTC
          </Button>
          <Button
            className={timeView === 'NZ' ? classes.pressed : ''}
            onClick={() => updateTimeView('NZ')}
          >
            NZ
          </Button>
          <Button
            className={timeView === 'Domain' ? classes.pressed : ''}
            onClick={() => updateTimeView('Domain')}
          >
            Domain
          </Button>
        </ButtonGroup>
        <TextField
          id="datetime-local"
          label="Target Time"
          type="datetime-local"
          value={moment(timeState).tz(timezone).format('YYYY-MM-DDTHH:mm')}
          onChange={e => setTimeState(moment.tz(e.target.value, timezone).toDate().getTime())}
          helperText={`Showing data from ${before} minutes before the moment to ${after} minutes after`}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            if (onBeforeRangeChange) {
              onBeforeRangeChange(before)
            }
            history.push(
              location.pathname +
                `?before=${before}&after=${after}&runTime=${new Date().getTime()}&timestamp=${timeState}`
            )}
          }
        >
          Run
        </Button>
      </Grid>
    </Panel>
  )
}

TimePicker.propTypes = {
  timezone: PropTypes.string.isRequired,
  onTimezoneChange: PropTypes.func.isRequired,
  onBeforeRangeChange: PropTypes.func,
}
